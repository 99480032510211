<template>
  <div>
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="w-full divide-y divide-gray-200" :class="{loading: loading}">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-28" @click="orderBy('DISPLAY_NAME')">
                  Name <br>Description
                  <span v-show="selectedOrder === 'DISPLAY_NAME'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Document Count
                </th>
                <th @click="orderBy('UPDATED_AT')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Updated
                  <span v-show="selectedOrder === 'UPDATED_AT'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th @click="orderBy('CREATED_AT')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Created
                  <span v-show="selectedOrder === 'CREATED_AT'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th scope="col" class="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="item in list" :key="item.id">
                <td class="px-3 py-4 whitespace-nowrap w-28">
                  <div class="flex items-center">
                    <div class="w-48" @click="view(item)">
                      <div class="text-sm font-medium text-indigo-400 cursor-pointer break-all w-48 whitespace-pre-wrap">{{item.displayName}}
                      </div>
                      <div class="text-sm text-gray-500 break-all w-48 whitespace-pre-wrap">
                        {{item.description}}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-3 py-4 whitespace-nowrap">
                  <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-200 text-yellow-800">
                    {{item.documentsByLibraryId.totalCount}}
                  </span>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <timeago :datetime="moment.utc(item.updatedAt)"></timeago>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <timeago :datetime="moment.utc(item.createdAt)"></timeago>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <a-tooltip title="Delete library">
                    <button
                      @click="showDelete = true; selected = item"
                      type="button" class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <svg class="h-5 w-5 text-red-400 inline-block cursor-pointer" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                    </button>
                  </a-tooltip>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- PAGINA -->
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <pagination v-model="page" :records="totalCount" @paginate="loadData"/>
      </div>
    </div>
    <Modal :show.sync="showDelete">
      <span slot="header">Are you sure delete this library?</span>
        <div class="sm:pt-5">
          <div class="w-full">
            <FormControl v-model="deleteValue" label="Enter 'DELETE' and press delete button to remove library"></FormControl>
          </div>
      </div>
      <div slot="footer">
        <button class="btn-cancel" @click="showDelete = false">
          Cancel
        </button>
         <button type="submit" class="btn-action" :disabled="deleteValue.toLowerCase() !== 'delete'" @click="deleteItem(selected)">
          Delete
        </button>
      </div>
    </Modal>
    <!-- <infinite-loading spinner="waveDots" @infinite="loadMore"></infinite-loading> -->
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'Libraries',
  props: {
    id: String,
  },
  data() {
    return {
      moment,
      selectedOrder: 'DISPLAY_NAME',
      orderDir: false, // false = asc, true = desc
      pageCount: 0,
      pageSize: 25,
      page: 1,
      offset: 0,
      loading: false,
      customer: null,
      deleteValue: '',
      selected: null,
      showDelete: false,
    };
  },
  computed: {
    ...mapState({
      list: (state) => state.library.list,
      hasNextPage: (state) => state.library.hasNextPage,
      totalCount: (state) => state.library.count,
    }),
    orderDirString() {
      return this.orderDir ? 'DESC' : 'ASC';
    },
    orderByString() {
      return `${this.selectedOrder}_${this.orderDirString}`;
    },
    queryParams() {
      const input = {
        first: 25,
        orderBy: this.orderByString,
        offset: this.offset,
        condition: {
          isDeleted: false,
        },
      };
      return input;
    },
  },
  mounted() {
    this.clearList();
    this.customer = this.$route.params.customer;
    this.loadData(1);
  },
  methods: {
    ...mapMutations({
      clearList: 'library/clearList',
    }),
    ...mapActions({
      fetch: 'library/fetch',
      create: 'library/create',
      deleteLib: 'library/delete',
    }),
    async deleteItem(item) {
      this.delete = '';
      const result = await this.deleteLib(item.id);
      console.log('deleted', result);
      if (!result.ok || result.error) {
        this.$message.error('Something went wrong');
        return;
      }
      await this.refresh();
      this.$message.success('Library deleted');
      this.showDelete = false;
      this.$emit('deleteLib', item);
      this.deleteValue = '';
    },
    async orderBy(type) {
      this.loading = true;
      if (type === this.selectedOrder) {
        this.orderDir = !this.orderDir;
        this.selectedOrder = type;
        await this.loadData(this.page);
        this.loading = false;
        return;
      }
      this.orderDir = false;
      this.selectedOrder = type;
      await this.loadData(this.page);
      this.loading = false;
    },
    async refresh() {
      this.loadData(this.page);
    },
    async loadData(page) {
      this.loading = true;
      console.log('page', page);
      this.offset = page * this.pageSize - this.pageSize;
      await this.fetch({ payload: this.queryParams, loadMore: false });
      this.loading = false;
    },
    view(item) {
      this.$router.push({ path: `/${this.customer}/libraries/${item.id}/documents` });
    },
  },
};
</script>
