<template>
  <div
    :class="{ 'sm:grid sm:grid-cols-2 sm:gap-0 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5': inline }">
    <label
      v-if="label"
      :for="fieldName"
      class="block text-sm font-medium text-gray-700"
      :class="{'pt-3': inline}"
      >{{ label }}</label>
    <div class="mt-1 relative rounded-md shadow-sm">
      <textarea @input="handleInput" :type="type" v-model="content" :name="fieldName" :id="fieldName" class="" :placeholder="placeholder" aria-invalid="true" aria-describedby="email-error" :class="{'form-control-error': error, 'form-control': !error}" rows="5"></textarea>
      <div
        v-if="error"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <!-- Heroicon name: solid/exclamation-circle -->
        <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
        </svg>
      </div>
    </div>
    <div></div>
    <p class="mt-2 text-sm text-red-600">
      <slot name="error">{{error}}</slot>
    </p>
  </div>

</template>
<script>
export default {
  name: 'FormControlTextarea',
  props: {
    value: String,
    label: String,
    placeholder: String,
    inline: {
      type: Boolean,
      default: false,
    },
    error: String,
    type: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.content = newValue;
    },
  },
  computed: {
    fieldName() {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < 5; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return `field-${result}`;
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.content);
    },
  },
};
</script>
