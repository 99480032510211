<template>
<div class="relative min-h-screen flex flex-col">
  <div class="pb-32">
    <nav class="flex-shrink-0 bg-indigo-600 z-50 relative">
        <div class="mx-auto px-2 sm:px-4 lg:px-8">
          <div class="relative flex items-center justify-between h-16">
            <!-- Logo section -->
            <div class="flex items-center px-2 lg:px-0 xl:w-64">
              <div class="flex-shrink-0">
                <img class="h-8 w-auto" src="../assets/logo.svg" alt="hydra">
              </div>
              <span class="text-white font-bold ml-3">{{userCustomer.displayName || customer}}</span>
            </div>
            <!-- Search section -->
            <div class="flex-1 flex justify-center lg:justify-end">
              <div class="w-full px-2 lg:px-6">
                <label for="search" class="sr-only">Search...</label>
                <div class="relative text-indigo-200 focus-within:text-gray-400">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <!-- Heroicon name: solid/search -->
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <input id="search"
                    @keyup.enter="search"
                    v-model="searchString"
                    name="search" class="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-indigo-400 bg-opacity-25 text-indigo-100 placeholder-indigo-200 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm" placeholder="Search library" type="search">
                    <div class="mt-1 sm:mt-0 sm:col-span-2 absolute top-0 right-0">
                      <select
                        v-model="libraryId"
                        id="country" name="country" autocomplete="country" class="block w-full pr-8 py-2 border border-transparent rounded-md rounded-l-none leading-5 bg-indigo-500  text-indigo-100 placeholder-indigo-200 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm">
                        <option
                          v-for="lib in libraries" :key="lib.id" :value="lib.id">{{lib.displayName}}</option>
                      </select>
                    </div>
                </div>
              </div>
            </div>
            <div class="flex lg:hidden">
              <!-- Mobile menu button -->
              <button
                @click="toggleMainMenu"
                class="bg-indigo-600 inline-flex items-center justify-center p-2 rounded-md text-indigo-400 hover:text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <!-- Icon when menu is closed. -->
                <!--
                  Heroicon name: outline/menu-alt-1

                  Menu open: "hidden", Menu closed: "block"
                -->
                <svg class="h-6 w-6"
                  :class="{hidden: showMobileNav, block: !showMobileNav}"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
                </svg>
                <!-- Icon when menu is open. -->
                <!--
                  Heroicon name: outline/x

                  Menu open: "block", Menu closed: "hidden"
                -->
                <svg class="h-6 w-6"
                  :class="{hidden: !showMobileNav, block: showMobileNav}"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <!-- Links section -->
            <div class="hidden lg:block lg:w-80">
              <div class="flex items-center justify-end">
                <div class="flex">
                </div>
                <!-- Profile dropdown -->
                <div class="ml-4 relative flex-shrink-0"
                    v-click-outside="closeUserMenu">
                  <div>
                    <button
                      @click="showDropdown = !showDropdown"
                      class="bg-indigo-700 flex text-sm rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white" id="user-menu" aria-haspopup="true">
                      <span class="sr-only">Open user menu</span>
                      <img class="h-8 w-8 rounded-full" :src="avatar" alt="">
                    </button>
                  </div>
                  <!--
                    Profile dropdown panel, show/hide based on dropdown state.

                    Entering: "transition ease-out duration-100"
                      From: "transform opacity-0 scale-95"
                      To: "transform opacity-100 scale-100"
                    Leaving: "transition ease-in duration-75"
                      From: "transform opacity-100 scale-100"
                      To: "transform opacity-0 scale-95"
                  -->
                  <transition
                    name="custom-classes-transition"
                    enter-class="transform opacity-0 scale-95"
                    enter-active-class="transition ease-out duration-100"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <div
                      v-show="showDropdown"
                      class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                      <router-link tag="a" :to="'/' + customer + '/user-profile'"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        View Profile
                      </router-link>
                      <a href="#"
                        @click="logOut"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Logout
                      </a>
                    </div>
                    </transition>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--
          Mobile menu, toggle classes based on menu state.

          Menu open: "block", Menu closed: "hidden"
        -->
        <div class="lg:hidden" :class="{hidden: !showMobileNav, block: showMobileNav}">
          <div class="pt-4 pb-3 border-t border-indigo-700">
            <div class="px-5 flex items-center">
              <div class="flex-shrink-0">
                <img class="rounded-full h-10 w-10" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=94Y6oZJI7Y&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
              </div>
              <div class="ml-3">
                <div class="text-base font-medium text-white">{{userEmail}}</div>
              </div>
            </div>
            <div class="mt-3 px-2 space-y-1">
              <router-link tag="a" :to="'/' + customer + '/user-profile'"
                class="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75">View Profile</router-link>
              <a
                @click="logOut"
                class="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75">Logout</a>
            </div>
          </div>
          <aside class="py-6 px-2 sm:px-2 lg:py-0 lg:px-0 lg:col-span-3 border-t border-indigo-700">
            <nav class="space-y-1">
              <router-link tag="a" :to="'/' + customer + '/dashboard'"
                class="sidebar-item-dark">
                <svg
                  class="sidebar-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                </svg>
                <span class="truncate text-white">
                  Dashboard
                </span>
              </router-link>

              <router-link tag="a" :to="'/' + customer + '/dashboard'"
                class="sidebar-item-dark">
                <!-- Heroicon name: outline/cog -->
                <svg
                  class="sidebar-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                </svg>
                <span class="truncate text-white">
                  Libraries
                </span>
              </router-link>

              <router-link tag="a" :to="'/' + customer + '/dashboard'"
                class="sidebar-item-dark">
                <svg
                  class="sidebar-icon"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
                <span class="truncate text-white">
                  Documents
                </span>
              </router-link>
              <router-link tag="a" :to="'/' + customer + '/dashboard'"
                class="sidebar-item-dark">
                <svg
                  class="sidebar-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
                </svg>
                <span class="truncate text-white">
                  Feeds
                </span>
              </router-link>
            </nav>
          </aside>
        </div>
    </nav>
  </div>
  <main class="-mt-32" style="min-height: calc(100vh - 50px)">
    <!-- 3 column wrapper -->
    <!-- Background color split screen for large screens -->
    <div class="mx-auto pb-10 lg:py-12 lg:px-8">
      <!-- Left sidebar & main wrapper -->
      <div class="lg:grid lg:grid-cols-12 lg:gap-x-5 pt-0 ">
        <!-- SIDEBAR -->
        <div class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
          <div class="pl-4 pr-6 py-6 sm:pl-6 lg:pl-8 xl:pl-0  hidden lg:block pt-0">
            <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
              <nav class="space-y-1">
                <!-- Current: "bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white", Default: "text-gray-900 hover:text-gray-900 hover:bg-gray-50" -->
                <!-- <a href="#" class="bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium" aria-current="page"> -->
                <router-link tag="a" :to="'/' + customer + '/dashboard'"
                  class="sidebar-item">
                  <!-- Current: "text-indigo-500 group-hover:text-indigo-500", Default: "text-gray-400 group-hover:text-gray-500" -->
                  <!-- Heroicon name: outline/user-circle -->
                  <svg
                    class="sidebar-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                  <span class="truncate">
                    Dashboard
                  </span>
                </router-link>

                <router-link tag="a" :to="'/' + customer + '/libraries'"
                  class="sidebar-item">
                  <svg
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                  </svg>
                  <span class="truncate">
                    Libraries
                  </span>
                </router-link>

                <router-link tag="a" :to="'/' + customer + '/documents'"
                class="sidebar-item">
                  <!-- Heroicon name: outline/credit-card -->
                  <svg
                    class="sidebar-icon"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                  <span class="truncate">
                    Documents
                  </span>
                </router-link>

                <router-link tag="a" :to="'/' + customer + '/feeds'"
                class="sidebar-item">
                  <!-- Heroicon name: outline/user-group -->
                  <svg
                    class="sidebar-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
                  </svg>
                  <span class="truncate">
                    Feeds
                  </span>
                </router-link>
              </nav>
            </aside>
          </div>
        </div>

        <!-- Projects List -->
        <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
          <router-view/>
        </div>
      </div>
    </div>
  </main>
  <div class="eu bg-white py-4 text-center">
    <img src="../assets/eu.webp" alt="" class="max-w-auto md:max-w-2xl mx-auto">
  </div>
</div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { Auth } from 'aws-amplify';

export default {
  data() {
    return {
      user: null,
      searchString: '',
      showDropdown: false,
      showMobileNav: false,
      libraryId: null,
    };
  },
  computed: {
    ...mapState({
      libraries: (state) => state.library.list,
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userEmail: (state) => state.auth.userEmail,
      customer: (state) => state.auth.customer,
      userCustomer: (state) => state.customer.currentCustomer,
    }),
    queryParams() {
      const input = {
        first: 125,
        orderBy: 'DISPLAY_NAME_ASC',
        offset: 0,
        condition: {
          isDeleted: false,
        },
      };
      return input;
    },
    avatar() {
      return `https://avatars.dicebear.com/api/bottts/${this.userEmail}.svg`;
    },
  },
  async mounted() {
    console.log('-> WRAPPER', this.$route.params.customer);
    if (this.$route.params.customer) {
      console.log('setCustomer');
      this.setCustomer(this.$route.params.customer);
      this.setCustomerId(this.$route.params.customer);
    }
    await this.fetch({ payload: this.queryParams });
    if (this.libraries[0]) {
      console.log('xxx', this.libraries[0]);
      this.libraryId = this.libraries[0].id;
    }
    this.checkInterval = window.setInterval(this.refreshToken, 10000);
  },
  methods: {
    ...mapActions({
      fetch: 'library/fetch',
    }),
    ...mapMutations({
      setSession: 'auth/setSession',
      setCustomer: 'auth/setCustomer',
      setIsLoggedIn: 'auth/setIsLoggedIn',
      setCustomerId: 'customer/setCustomerId',
    }),
    toggleMainMenu() {
      console.log('toggleMainMenu');
      this.showMobileNav = !this.showMobileNav;
    },
    closeUserMenu() {
      this.showDropdown = false;
    },
    search() {
      const path = `/${this.customer}/search`;
      this.$router.push({ path, query: { libraryId: this.libraryId, s: this.searchString } });
    },
    async logOut() {
      await this.$Amplify.Auth.signOut();
      this.setIsLoggedIn(false);
      this.$router.push({ path: `/${this.customer}/login` });
    },
    async checkIfLogged() {
      if (this.isLoggedIn) return;
      const result = await Auth.currentSession();
      console.log('USER SESSION', result);
      this.setSession(result);
    },

    async refreshToken() {
      const result = await Auth.currentSession();
      console.log('refreshToken', result);
      const hasAccessToken = !!result.accessToken;
      if (hasAccessToken) localStorage.setItem('token', result.accessToken.jwtToken);
    },
  },
};
</script>
