<template>
  <div>
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="w-full divide-y divide-gray-200" :class="{loading: loading}">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-28" @click="orderBy('DISPLAY_NAME')">
                  Name <br>Description
                  <span v-show="selectedOrder === 'DISPLAY_NAME'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th @click="orderBy('CONTENT_TYPE')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Type
                  <span v-show="selectedOrder === 'CONTENT_TYPE'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th @click="orderBy('UPDATED_AT')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Updated
                  <span v-show="selectedOrder === 'UPDATED_AT'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th @click="orderBy('CREATED_AT')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Created
                  <span v-show="selectedOrder === 'CREATED_AT'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th @click="orderBy('LAST_CHECKED_AT')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Last Checked
                  <span v-show="selectedOrder === 'LAST_CHECKED_AT'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th scope="col" class="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="item in attachedFeeds" :key="item.id" :class="{ 'opacity-30': !item.attached }">
                <td class="px-3 py-4 whitespace-nowrap w-28">
                  <div class="flex items-center" @click="select(item)">
                    <div class="w-48">
                      <div class="text-sm font-medium text-indigo-400 cursor-pointer break-all w-48 whitespace-pre-wrap">{{item.displayName}}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{item.description}}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-3 py-4 whitespace-nowrap">
                  <span :class="item.type">
                    {{item.type}}
                  </span>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <timeago :datetime="moment.utc(item.updatedAt)"></timeago>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <timeago :datetime="moment.utc(item.createdAt)"></timeago>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <timeago :datetime="moment.utc(item.lastCheckedAt)"></timeago>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <a-tooltip title="Attach Feed">
                    <a-popconfirm
                      title="Are you sure yu want to attach this feed?"
                      ok-text="Attach"
                      cancel-text="Cancel"
                      @confirm="attachFeed(item)"
                    >
                      <button type="button" class="inline-flex items-center mr-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      v-if="id && !item.attached">
                        <i class="fas fa-link fill-current text-green-600"></i>
                      </button>
                    </a-popconfirm>
                  </a-tooltip>
                  <a-tooltip title="Detach Feed">
                    <a-popconfirm
                      title="Are you sure yu want to detach this feed?"
                      ok-text="Detach"
                      cancel-text="Cancel"
                      @confirm="detachFeed(item)"
                    >
                      <button type="button" class="inline-flex items-center mr-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      v-if="id && item.attached">
                        <i class="fas fa-unlink fill-current text-red-600"></i>
                      </button>
                    </a-popconfirm>
                  </a-tooltip>
                  <a-tooltip title="Delete feed">
                    <a-popconfirm
                      title="Are you sure delete this feed?"
                      ok-text="Delete"
                      cancel-text="Cancel"
                      @confirm="deleteItem(item)"
                    >
                      <button
                        v-if="!id"
                        type="button" class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <svg class="h-5 w-5 text-red-400 inline-block cursor-pointer" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                      </button>
                    </a-popconfirm>
                  </a-tooltip>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-7 mb-7" v-show="attachedFeeds.length === 0 && !loading">
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
          <svg
            class="h-6 w-6 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            There is no feeds
          </h3>
          <div class="mt-2">
          </div>
        </div>
      </div>
      <!-- PAGINA -->
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <pagination v-model="page" :records="attachedFeeds.length" @paginate="loadData"/>
      </div>
    </div>
    <!-- <infinite-loading spinner="waveDots" @infinite="loadMore"></infinite-loading> -->
    <Modal :show.sync="showDetails">
      <span slot="header">Feed Details</span>
        <div class="sm:pt-5">
          <div class="w-full">
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="bg-white overflow-hidden sm:rounded-lg -mx-6 -mt-0" v-if="selected">
              <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-gray-200">
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Name
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selected.displayName}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Description
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selected.description}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Feed Type
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span :class="selected.type">
                        {{selected.type}}
                      </span>
                    </dd>
                  </div>
                </dl>
                <div class="hidden sm:block">
                  <div class="pl-4">
                    <nav class="flex space-x-4" aria-label="Tabs">
                      <a @click="activeTab = 1" class="tab" :class="{active: activeTab === 1}">
                        Metadata
                      </a>
                      <a @click="activeTab = 2" class="tab" :class="{active: activeTab === 2}">
                        Libraries
                      </a>
                    </nav>
                    <div class="content mr-4 mt-3" v-if="activeTab === 1 && selected.metadata" >
                      <div class="mt-7 mb-7" v-if="Object.keys(selected.metadata).length === 0">
                        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                          <svg
                            class="h-6 w-6 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div class="mt-3 text-center sm:mt-5">
                          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            No metadata
                          </h3>
                          <div class="mt-2">
                          </div>
                        </div>
                      </div>
                      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200" v-if="Object.keys(selected.metadata).length > 0">
                        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                          v-for="(data, key) in selected.metadata" :key="key">
                          <div class="w-0 flex-1 flex items-center">
                            <span class="ml-2 flex-1 w-0 truncate uppercase">
                              {{key.replace(/([a-z])([A-Z])/g, '$1 $2')}}
                            </span>
                          </div>
                          <div class="ml-4 flex-shrink-0 break-all whitespace-pre-wrap" style="max-width:200px">
                            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">{{data}}
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="content mr-4 mt-3" v-if="activeTab === 2">
                      <div class="mt-7 mb-7" v-if="selectedLibs.length === 0">
                        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                          <svg
                            class="h-6 w-6 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div class="mt-3 text-center sm:mt-5">
                          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            No libraries attached
                          </h3>
                          <div class="mt-2">
                          </div>
                        </div>
                      </div>
                      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200" v-if="selectedLibs.length > 0">
                        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                          v-for="(data, key) in selectedLibs" :key="key">
                          <div class="w-0 flex-1 flex items-center">
                            <span class="ml-2 flex-1 w-0 truncate">
                              {{data.libraryByLibraryId.displayName}}
                            </span>
                          </div>
                          <div class="ml-4 flex-shrink-0">
                            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500 overflow-ellipsis overflow-hidden">
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
      </div>
      <div slot="footer">
         <button type="submit" class="btn-action" @click="showDetails = false">
          OK
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'Feeds',
  props: {
    id: String,
  },
  data() {
    return {
      moment,
      selectedOrder: 'DISPLAY_NAME',
      orderDir: false, // false = asc, true = desc
      pageCount: 0,
      loading: false,
      pageSize: 25,
      page: 1,
      offset: 0,
      showDetails: false,
      selected: {},
      activeTab: 1,
    };
  },
  computed: {
    ...mapState({
      list: (state) => state.feed.list,
      detached: (state) => state.feed.detached,
      hasNextPage: (state) => state.feed.hasNextPage,
      totalCount: (state) => state.feed.count,
    }),
    orderDirString() {
      return this.orderDir ? 'DESC' : 'ASC';
    },
    orderByString() {
      return `${this.selectedOrder}_${this.orderDirString}`;
    },
    queryParams() {
      const input = {
        first: 25,
        orderBy: this.orderByString,
        offset: this.offset,
      };

      if (this.id) {
        input.condition = {
          libraryId: this.id,
        };
      }

      return input;
    },
    selectedLibs() {
      if (!this.selected || !this.selected.libraryFeedsByFeedId) return {};
      return this.selected.libraryFeedsByFeedId.nodes;
    },
    attachedFeeds() {
      return this.list.filter((el) => el.attached);
    },
  },
  mounted() {
    this.clearList();
    this.loadData(1);
  },
  methods: {
    ...mapMutations({
      clearList: 'feed/clearList',
    }),
    ...mapActions({
      fetch: 'feed/fetch',
      attach: 'feed/attach',
      detach: 'feed/detach',
      create: 'feed/create',
      deleteFeed: 'feed/deleteItem',
    }),
    async deleteItem(item) {
      this.$emit('deleteFeed', item);
      const result = await this.deleteFeed(item.id);
      if (!result.ok || result.error) {
        this.$message.error('Something went wrong');
        return;
      }
      await this.refresh();
      this.$message.success('Feed deleted');
    },
    async orderBy(type) {
      this.loading = true;
      if (type === this.selectedOrder) {
        this.orderDir = !this.orderDir;
        this.selectedOrder = type;
        await this.fetch({ payload: this.queryParams, loadMore: false });
        this.loading = false;
        return;
      }
      this.orderDir = false;
      this.selectedOrder = type;
      await this.fetch({ payload: this.queryParams, loadMore: false });
      this.loading = false;
    },
    async loadData(page) {
      this.loading = true;
      console.log('page', page);
      this.offset = page * this.pageSize - this.pageSize;
      await this.fetch({ payload: this.queryParams, loadMore: false });
      this.loading = false;
    },
    async detachFeed(item) {
      console.log('attach', item);
      const input = {
        feedId: item.id,
        libraryId: this.id,
      };

      const result = await this.detach(input);
      console.log('result');
      if (result.err || result.errors) {
        this.$message.error('Something went wrong');
        return;
      }
      this.clearList();
      this.$message.success('Feed detached');
      this.refresh();
    },
    async attachFeed(item) {
      console.log('attach', item);
      const input = {
        feedId: item.id,
        libraryId: this.id,
      };

      const result = await this.attach(input);
      if (result.err || result.errors) {
        this.$message.error('Something went wrong');
        return;
      }
      this.$message.success('Feed artached');
      this.clearList();
      this.refresh();
    },
    async refresh() {
      this.loadData(this.page);
    },
    select(item) {
      this.selected = item;
      this.showDetails = true;
    },
  },
};
</script>
