/* eslint-disable no-shadow */
import Vue from 'vue';
import { API } from 'aws-amplify';
// import GQL from '../graphql';

const state = {
  name: 'Search',
  loading: false,
  results: [],
  resultSentences: [],
};

const getters = {
  // eslint-disable-next-line
  uuidv4: () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  },
};

const mutations = {
  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },

  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },

  setResults: (state, payload) => {
    const sentences = [];
    const result = payload[1].map((el) => {
      console.log('-', el);
      const newEl = {
        ...el,
        opened: false,
        loading: false,
        libraryByLibraryId: {
          displayName: 'Library name',
        },
        contentType: 'none',
        name: el.doc_id,
        indexedAt: new Date(),
      };
      let videoId = null;
      let videoUrl = null;
      const hasVideo = el.doc_source && el.doc_source.endsWith('.vtt');

      if (hasVideo) {
        videoId = el.doc_source.split('/').pop().split('.').shift();
        videoUrl = `https://www.youtube.com/watch?v=${videoId}`;
      }

      el.sentences.forEach((item, index) => {
        const startWithBigLetter = item.charAt(0) === item.charAt(0).toUpperCase();
        const metadata = el.metadata[index] || {};
        const videoUrlWithStartTime = `${videoUrl}?t=${metadata.start_time}`;
        sentences.push({
          hasVideo,
          id: el.id,
          doc_source: el.doc_source,
          videoId,
          videoUrl,
          videoUrlStart: videoUrlWithStartTime,
          beginTime: metadata.start_time,
          videoTitle: item,
          time: metadata,
          metadata,
          score: el.score,
          startElipsis: !startWithBigLetter,
          endsWithElispis: item.charAt(item.length) !== '.',
          name: item,
        });
      });
      return newEl;
    });
    Vue.set(state, 'results', result);
    Vue.set(state, 'resultSentences', sentences);
  },
};

const actions = {
  async fetchResults({ commit }, payload) {
    const apiName = 'mainApi';
    const path = `/searchLibrary?libraryId=${payload.libraryId}&query=${payload.query}`;
    const token = localStorage.getItem('token');
    const myInit = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await API.get(apiName, path, myInit);
      console.log('response', response);
      const nodes = response;
      commit('setResults', nodes);
      commit('setLoading', true);
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(this._vm);
      return nodes;
    } catch (error) {
      console.log('error', error);
      this._vm.$message.error('Can`t get search results');
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
