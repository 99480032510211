<template>
  <div class="search-results">
    <div>
      <div>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
          <ol class="flex items-center space-x-4">
            <li>
              <div>
                <a href="#" class="text-sm font-medium text-gray-500 hover:text-gray-700">Search</a>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
                <a href="#" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Search results</a>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
                <a href="#" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{searchQuery}}</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="flex-1 min-w-0">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Results for : {{searchQuery}}
          </h2>
        </div>
        <div class="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
        </div>
      </div>
      <div class="no-results text-center h-52 pt-7 bg-white shadow overflow-hidden sm:rounded-md mt-4" v-show="resultSentences.length === 0 && !searching">
        <div>
          <div class="text-center"><svg class="w-20 h-20 mx-auto text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg></div>
          <div class="text-xl mt-4">We're Sorry! We didn't find antthing for "<strong>{{searchQuery}}</strong>"</div>
        </div>
      </div>
      <div class="loading" v-show="searching">
        <div class="bg-white shadow overflow-hidden sm:rounded-md mt-4 py-12"></div>
      </div>
      <div class="bg-white shadow overflow-hidden sm:rounded-md mt-4" v-show="resultSentences.length !== 0">
        <ul class="divide-y divide-gray-200">
          <li v-for="(doc, index) in resultSentences" :key="doc.id + index" :class="{loading: doc.opened}">
            <a href="#" class="block hover:bg-gray-50">
              <div class="px-4 py-4 flex items-center sm:px-6">
                <div class="flex-shrink-0 -ml-3 mr-3">

                  <svg class="w-6 h-6" v-if="doc.hasVideo" @click="playVideo(doc)" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd"></path></svg>
                </div>
                <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between min-h-full"  @click="getDocInfo(doc)">
                  <div style="max-width: 80%">
                    <div class="flex text-sm font-medium text-indigo-600 " >
                      <p class="mb-0  " >
                        <span v-show="doc.startElipsis">...</span> {{doc.name}}
                        <span v-show="doc.endsWithElispis">...</span>
                        <!-- {{gen.createParagraph(5)}} -->
                      </p>
                      <p class="ml-1 font-normal text-gray-500 mb-0 pb-0">
                        <!-- in {{libraryId}} -->
                        <!-- {{gen.createFullName(false)}} -->
                      </p>
                    </div>
                  </div>
                  <div class="mt-4 flex-shrink-0 sm:mt-0">
                    <div class="flex overflow-hidden">
                      <span class="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-red-400 text-white">
                        {{round(doc.score, 3)}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="ml-5 flex-shrink-0">
                  <button type="button"
                    @click="download(doc)"
                    class="inline-flex docs-center p-2 border border-transparent rounded-full shadow-sm text-white bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
                    :class="{loading: doc.loading}">
                    <!-- Heroicon name: solid/plus -->
                    <svg class="h-5 w-5 text-indigo-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd"></path></svg>
                  </button>
                </div>
              </div>
            </a>
            <div class="content border-t bg-gray-50 border-fuchsia-600 divide-y text-sm" :class="{'h-0 overflow-hidden border-none': !doc.opened}">
              <!-- {{doc.sentences}} -->
              <div class="ml-11 py-2" v-for="sen in doc.sentences" :key="sen.text">
                {{sen.text}}
                <!-- {{gen.createParagraph(15)}} -->
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Modal :show.sync="showDetails">
      <span slot="header">Document Details</span>
        <div class="sm:pt-5">
          <div class="w-full">
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="bg-white overflow-hidden sm:rounded-lg -mx-6 -mt-0">
              <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-gray-200">
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Name
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.name}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Directory
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.directory}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Library
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDocLibrary(selectedDoc)}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Content Type
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span class="type"
                        :class="selectedDoc.contentType ? selectedDoc.contentType.replace('\/', '-') : ''">
                        {{selectedDoc.contentType}}
                      </span>
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Content hash
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.contentHash}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Original URI
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.originalUri}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Created at <br>(updated at)
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <timeago :datetime="moment.utc(selectedDoc.createdAt)" v-if="selectedDoc.createdAt"></timeago> (<timeago :datetime="moment.utc(selectedDoc.updatedAt)" v-if="selectedDoc.updatedAt"></timeago>)
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Processing Stage
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.processingStage}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Processing Status
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div
                        :class="selectedDoc.processingStatus">
                        <span class="status h-4 w-4 rounded-full flex items-center justify-center" aria-hidden="true">
                          <span class="h-2 w-2 rounded-full"></span>
                        </span>
                        <span>{{selectedDoc.processingStatus}}</span>
                      </div>
                      <div class="text-red-500 text-xs mt-2">
                        {{selectedDoc.processingError}}
                      </div>
                    </dd>
                  </div>
                </dl>
                <div class="hidden sm:block">
                  <div class="pl-4">
                    <nav class="flex space-x-4" aria-label="Tabs">
                      <a @click="activeTab = 1" class="tab" :class="{active: activeTab === 1}">
                        Metadata
                      </a>
                      <a @click="activeTab = 2" class="tab" :class="{active: activeTab === 2}">
                        Indices State
                      </a>
                    </nav>
                    <div class="content mr-4 mt-3" v-if="activeTab === 1">
                      <div class="mt-7 mb-7" v-if="Object.keys(selectedDoc.metadata).length === 0">
                        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                          <svg
                            class="h-6 w-6 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div class="mt-3 text-center sm:mt-5">
                          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            No metadata
                          </h3>
                          <div class="mt-2">
                          </div>
                        </div>
                      </div>
                      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200" v-if="Object.keys(selectedDoc.metadata).length !== 0">
                        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                          v-for="(data, key) in selectedDoc.metadata" :key="key">
                          <div class="w-0 flex-1 flex items-center">
                            <span class="ml-2 flex-1 w-0 truncate uppercase">
                              {{key.replace(/([a-z])([A-Z])/g, '$1 $2')}}
                            </span>
                          </div>
                          <div class="ml-4 flex-shrink-0 break-all whitespace-pre-wrap" style="max-width:200px">
                            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">{{data}}
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="content mr-4 mt-3" v-if="activeTab === 2">
                      <div class="mt-7 mb-7" v-if="selectedDocIndex.length === 0">
                        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                          <svg
                            class="h-6 w-6 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div class="mt-3 text-center sm:mt-5">
                          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            No related induces
                          </h3>
                          <div class="mt-2">
                          </div>
                        </div>
                      </div>
                      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200" v-if="selectedDocIndex.length !== 0">
                        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                          v-for="(data, key) in selectedDocIndex" :key="key">
                          <div class="w-0 flex-1 flex items-center">
                            <span class="ml-2 flex-1 w-0 truncate uppercase">
                              {{data.indexByIndexId.type}}
                            </span>
                          </div>
                          <div class="ml-4 flex-shrink-0">
                            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500 overflow-ellipsis overflow-hidden">
                              {{data.state}}
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
      </div>
      <div slot="footer">
         <button type="submit" class="btn-action" @click="showDetails = false">
          OK
        </button>
      </div>
    </Modal>
    <div class="video-popup" v-if="showVideo">
        <div class="overlay"></div>
        <div class="content bg-indigo-600 rounded-md">
          <div class="title">
            {{selectedVideo.videoTitle}}
            <div class="close" @click="closePreview"></div>
          </div>
          <iframe height="100%" width="100%" :src="previewUrl" ref="video-player" allow='autoplay'>
          </iframe>
        </div>
      </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import moment from 'moment';

const Jabber = require('jabber');

export default {
  data() {
    return {
      searchQuery: '',
      gen: new Jabber(),
      libraryId: '',
      selectedVideo: null,
      searchingComplete: false,
      searching: false,
      selectedDoc: {
        metadata: {},
      },
      showDetails: false,
      activeTab: 1,
      moment,
      docs: [
        {
          id: 1,
          name: 'Lorem ipsum sit dolor',
          contentType: 'type',
          libraryByLibraryId: {
            displayName: 'Library name',
          },
          indexedAt: new Date(),
          score: 77,
          opened: false,
        },
        {
          id: 2,
          name: 'Lorem ipsum sit dolor',
          contentType: 'type',
          libraryByLibraryId: {
            displayName: 'Library name',
          },
          indexedAt: new Date(),
          score: 34,
          opened: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userEmail: (state) => state.auth.userEmail,
      customer: (state) => state.auth.customer,
      results: (state) => state.search.results,
      resultSentences: (state) => state.search.resultSentences,
    }),
    round() {
      return (num, dec) => {
        console.log('num', num, dec);
        if (!num) return 0;
        const [sv, ev] = num.toString().split('e');
        // eslint-disable-next-line
        return Number(Number(Math.round(parseFloat(sv + 'e' + dec)) + 'e-' + dec) + 'e' + (ev || 0));
      };
    },
    showVideo() {
      return this.selectedVideo && this.selectedVideo.videoId;
    },
    selectedDocLibrary() {
      return (doc) => {
        console.log('doc', doc);
        return doc && doc.libraryByLibraryId ? doc.libraryByLibraryId.displayName : 'not set';
      };
    },
    selectedDocIndex() {
      if (!this.selectedDoc || !this.selectedDoc.documentIndexStatesByDocumentId) return {};
      return this.selectedDoc.documentIndexStatesByDocumentId.nodes;
    },
    previewUrl() {
      const params = `?autoplay=1&start=${Math.round(this.selectedVideo.beginTime)}`;
      return `https://www.youtube.com/embed/${this.selectedVideo.videoId}${params}`;
    },
  },
  watch: {
    // eslint-disable-next-line
    '$route.query.libraryId': function() {
      this.search();
    },
    // eslint-disable-next-line
    '$route.query.s': function() {
      console.log('s changed');
      this.search();
    },
  },
  mounted() {
    console.log('-> SEARCH', this.$route);
    this.search();
  },
  methods: {
    ...mapMutations({
      setSession: 'auth/setSession',
      setCustomer: 'auth/setCustomer',
    }),
    ...mapActions({
      fetchResults: 'search/fetchResults',
      generateUrl: 'documents/generateUrl',
      fetchDocument: 'documents/fetchDocument',
    }),
    async search() {
      this.searchingComplete = false;
      this.searching = true;
      this.searchQuery = this.$route.query.s;
      this.libraryId = this.$route.query.libraryId;
      console.log('search Query', this.searchQuery);
      const result = await this.fetchResults({ query: this.searchQuery, libraryId: this.libraryId });
      this.searching = false;
      this.searchingComplete = true;
      console.log(result);
    },
    async getDocInfo(doc) {
      const document = doc;
      doc.opened = true; // eslint-disable-line
      const result = await this.fetchDocument({ id: doc.id });
      document.opened = false;
      console.log('%cdoc', 'color: lime', doc, result);
      if (!result.id) {
        this.$message.error('Something went wrong. Can`t get document details');
        return;
      }
      this.selectedDoc = result;
      this.showDetails = true;
      console.log('doc', doc, result);
    },
    async download(item) {
      // eslint-disable-next-line
      item.loading = true;
      console.log('DOWNLOAD DOCUMENT', item);
      const result = await this.generateUrl({
        documentId: item.doc_id,
        libraryId: this.libraryId,
      });
      if (!result.ok) {
        // eslint-disable-next-line
        item.loading = false;
        this.$message.error('Something went wrong. Can`t generate URL for selected document');
        return;
      }
      this.downloadItem = item;
      this.downloadItem.url = result.url;
      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);
      // eslint-disable-next-line
      item.loading = false;

      // Set the HREF to a Blob representation of the data to be downloaded
      a.href = result.url;

      // Use download attribute to set set desired file name
      a.setAttribute('download', item.name);
      a.setAttribute('target', '_blank');

      // Trigger the download by simulating click
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
      //  saveAs
    },
    closePreview() {
      this.selectedVideo = null;
    },
    // not working ATM
    focusPlayer() {
      if (!this.$refs['video-player']) return;
      this.$refs['video-player'].contentWindow.focus();
    },
    playVideo(video) {
      this.selectedVideo = video;
      this.focusPlayer();
      return false;
    },
  },
};
</script>
