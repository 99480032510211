/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../graphql';

const state = {
  name: 'Indices',
  loading: false,
  list: [],
  count: 0,
  hasNextPage: true,
  pageInfo: {},
  loadings: {
  },
  types: {},
  usedSpace: 0,
};

const getters = {
};

const mutations = {
  setList: (state, payload) => {
    if (payload.loadMore) {
      Vue.set(state, 'list', [
        ...state.list,
        ...payload.nodes,
      ]);
    } else {
      Vue.set(state, 'list', payload.nodes.filter((el) => !el.isDeleted));
    }
  },
  clearList: (state) => {
    Vue.set(state, 'list', []);
    Vue.set(state, 'hasNextPage', true);
  },
  setCount: (state, payload) => {
    Vue.set(state, 'count', payload);
  },
  setUsedSpace: (state, payload) => {
    const total = payload.reduce((a, b) => {
      console.log('a + b', a, b);
      return parseInt(a, 10) + parseInt(b.usedSpace, 10);
    }, 0);
    console.log('usedSpace', total);
    Vue.set(state, 'usedSpace', total);
  },
  setPageInfo: (state, payload) => {
    Vue.set(state, 'hasNextPage', payload.hasNextPage);
    Vue.set(state, 'pageInfo', payload);
  },
  setTypes: (state, payload) => {
    Vue.set(state, 'types', payload);
  },

  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },
};

const actions = {
  async getCount({ commit }) {
    console.log('getCount');
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.IndicesCount),
      );
      const { totalCount, nodes } = response.data.allIndices;
      commit('setCount', totalCount);
      commit('setUsedSpace', nodes);
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(this._vm);
      return totalCount;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log(error);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async getTypes({ commit }) {
    console.log('getTypes');
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.IndexTypes),
      );
      const { indexTypes } = response.data;
      commit('setTypes', indexTypes);
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(this._vm);
      return indexTypes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log(error);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetch({ commit }, { payload, loadMore }) {
    console.log('fetch loadMore', loadMore);
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllIndices, payload),
      );
      const { nodes, pageInfo, totalCount } = response.data.allIndices;
      commit('setList', { nodes, loadMore });
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(this._vm);
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log('err', error, payload);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async deleteItem({ commit }, payload) {
    console.log('deleteItem');
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.DeleteIndex, {
          input: {
            indexId: payload,
          },
        }),
      );
      const { ok, err, indexId } = response.data.deleteIndex;
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(ok, err, indexId);
      commit('setLoadings', { type: 'delete', value: false });
      return response.data.deleteIndex;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log(error);
      return { err: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async create({ commit }, payload) {
    console.log('create', payload);
    commit('setLoadings', { type: 'index', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CreateIndex, {
          input: payload,
        }),
      );
      const { createIndex } = response.data;
      return createIndex;
    } catch (error) {
      return { err: true, message: error.errors, ok: false };
    } finally {
      commit('setLoadings', { type: 'index', value: false });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
