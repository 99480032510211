<template>
 <div id="reset">
    <div class="min-h-screen bg-gray-500-contrast flex">
      <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img class="h-12 w-auto" src="../assets/logo-full-white.svg">
            <h2 class="mt-6 text-3xl font-extrabold text-white"  v-if="visibleStep !== 3">
              Password reset
            </h2>
            <h2 class="mt-6 text-3xl font-extrabold text-white"  v-if="visibleStep === 3">
              Your password has been changed.
            </h2>
            <p class="mt-2 text-sm text-gray-400 max-w" v-if="visibleStep !== 3">
              Or
              <router-link
                :to="'/' + customer + '/login'"
                tag="a"
                class="font-medium text-indigo-400 hover:text-indigo-300">login to existing one</router-link>
            </p>
            <div class="message text-red-500 text-xs mt-1" v-if="errorMessage">
              {{ errorMessage }}
            </div>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <form @submit.prevent="resetPasswordStepOne" class="space-y-6" v-if="visibleStep == 1">
                <div>
                  <label for="email" class="block text-sm font-medium text-gray-300">
                    Email address
                  </label>
                  <div class="mt-1 relative">
                    <input
                      v-model="email"
                      id="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      :class="{'ring-2 ring-green-500': email && validation.isPassed('email')}">
                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" v-if="email && validation.hasError('email') && validation.isTouched('email')">
                        <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                      </div>
                  </div>
                  <div class="message text-red-500 text-xs mt-1">
                    {{ validation.firstError('email') }}
                    <i v-if="validation.isValidating('email')" class="fa fa-spinner fa-spin"></i>
                    <i v-if="email && validation.isPassed('email')" class="text-success fa fa-check-circle"></i>
                  </div>
                </div>
                <div>
                  <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Reset
                  </button>
                </div>
              </form>
              <form @submit.prevent="resetPasswordStepOne" class="space-y-6" v-if="visibleStep == 2">
                <p class="mt-2 text-sm text-gray-400 max-w">
                  Enter the code recived in the email and new password
                </p>
                <div>
                  <label for="code" class="block text-sm font-medium text-gray-300">
                    Code
                  </label>
                  <div class="mt-1 relative">
                    <input
                      v-model="code"
                      id="code" name="code" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      :class="{'ring-2 ring-green-500': code && validation.isPassed('code')}">
                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" v-if="code && validation.hasError('code') && validation.isTouched('code')">
                        <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                      </div>
                  </div>
                  <div class="message text-red-500 text-xs mt-1">
                    {{ validation.firstError('code') }}
                    <i v-if="validation.isValidating('code')" class="fa fa-spinner fa-spin"></i>
                    <i v-if="code && validation.isPassed('code')" class="text-success fa fa-check-circle"></i>
                  </div>
                </div>
                <div class="space-y-1">
                  <label for="password"
                    class="block text-sm font-medium text-gray-300">
                    Password
                  </label>
                  <div class="mt-1 relative">
                    <input
                    v-model="password"
                    id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      v-if="password && validation.hasError('password') && validation.isTouched('password')">
                      <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  <div class="message text-red-500 text-xs mt-1" v-if="validation.isTouched('password')">
                    {{ validation.firstError('password') }}
                    <i v-if="validation.isValidating('password')" class="fa fa-spinner fa-spin"></i>
                    <i v-if="password && validation.isPassed('password')" class="text-success fa fa-check-circle"></i>
                  </div>
                </div>
                <div class="space-y-1">
                  <label for="password"
                    class="block text-sm font-medium text-gray-300">
                    Confirm Password
                  </label>
                  <div class="mt-1 relative">
                    <input
                    v-model="confirmPassword"
                    id="confirmPassword" name="confirmPassword" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      v-if="confirmPassword && validation.hasError('confirmPassword') && validation.isTouched('confirmPassword')">
                      <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  <div class="message text-red-500 text-xs mt-1" v-if="validation.isTouched('confirmPassword')">
                    {{ validation.firstError('confirmPassword') }}
                    <i v-if="validation.isValidating('confirmPassword')" class="fa fa-spinner fa-spin"></i>
                    <i v-if="confirmPassword && validation.isPassed('confirmPassword')" class="text-success fa fa-check-circle"></i>
                  </div>
                </div>
                <div>
                  <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Confirm
                  </button>
                </div>
              </form>
              <div v-if="visibleStep == 3">
                <router-link
                  :to="'/' + customer + '/login'"
                  tag="a"
                  class="btn mt-3">Go to login page</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden lg:block relative w-0 flex-1">
        <img class="absolute inset-0 h-full w-full object-cover" src="../assets/bg.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  data() {
    return {
      visibleStep: 1,
      username: null,
      isBtnLoading: false,
      error: null,
      email: null,
      code: null,
      password: null,
      confirmPassword: null,
      errorMessage: null,
      showLoginMessage: false,
      submitted: false,
      customer: null,
    };
  },
  validators: {
    // eslint-disable-next-line
    email: (value) => Validator.value(value).required().email(),
    code: (value) => Validator.value(value).required(),
    password: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    // eslint-disable-next-line
    'confirmPassword, password': function(confirmPassword, password) {
      console.log('sss');
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword).required().match(password);
      }
      return false;
    },
  },
  mounted() {
    this.customer = this.$route.params.customer;
  },

  methods: {
    btnText(text) {
      if (this.isBtnLoading) return 'Loading...';
      return text;
    },
    handleResetSubmit(e) {
      e.preventDefault();
      this.newPasswordForm.validateFieldsAndScroll((err, values) => {
        console.log('reset values', values);
        if (!err) {
          this.forgotPasswordSubmit(values);
        }
      });
    },
    handleConfirmBlur(e) {
      const { value } = e.target;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const { newPasswordForm } = this;
      if (value && value !== newPasswordForm.getFieldValue('password')) {
        callback('Two passwords that you enter are inconsistent!');
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const { newPasswordForm } = this;
      if (value && this.confirmDirty) {
        newPasswordForm.validateFields(['confirm'], { force: true });
      }
      callback();
    },
    // STEP ONE
    async resetPasswordStepOne() {
      const isValid = await this.$validate('email');
      console.log('login', isValid);
      if (!isValid) return;
      this.loading = true;
      try {
        this.loading = true;
        await this.$Amplify.Auth.forgotPassword(this.email);
        this.visibleStep = 2;
        this.loading = false;
      } catch (error) {
        this.error = error.message;
        this.loading = false;
      }
    },

    async forgotPasswordSubmit() {
      console.log('forgotPasswordSubmit values', this.email);
      const isValid = await this.$validate(['code', 'password', ['confirmPassword']]);
      console.log('login', isValid);
      if (!isValid) return;
      try {
        this.isBtnLoading = true;
        await this.$Amplify.Auth.forgotPasswordSubmit(
          this.email,
          this.code,
          this.password,
        );
        this.visibleStep = 3;
        this.isBtnLoading = false;
      } catch (error) {
        this.error = error.message;
        this.isBtnLoading = false;
      }
    },

    goToLogin() {
      this.$router.push({ path: 'login' });
    },
  },
};
</script>
