<template>
  <div class="">
    <router-view/>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

const Jabber = require('jabber');

export default {
  data() {
    return {
      showCreateNew: false,
      showFilters: false,
      gen: new Jabber(),
      libs: [],
      libraryForm: {
        displayName: '',
        description: '',
      },
    };
  },
  computed: {
    ...mapState({
      libraries: (state) => state.library.list,
      docCount: (state) => state.library.count,
      customer: (state) => state.auth.customerId,
      customers: (state) => state.customer.list,
    }),
  },
  validators: {
    // eslint-disable-next-line
    'libraryForm.displayName': (value) => Validator.value(value).required(),
  },
  mounted() {
    console.group('LIBRARIES WRAPPER');
    console.log('-> LIBRARIES WRAPPER');
    this.init();
  },
  beforeDestroy() {
    console.groupEnd('LIBRARIES WRAPPER');
  },
  methods: {
    ...mapActions({
      fetch: 'library/fetch',
      create: 'library/create',
      delete: 'library/delete',
    }),
    async init() {
      // await this.fetch();
    },
  },
};
</script>
