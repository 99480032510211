<template>
  <div class="">
    <PageHeader>
      Libraries
      <div class="mt-4 flex md:mt-0 md:ml-4" slot="actions"
          v-click-outside="closeFilters">
        <button
          @click="showCreateNew = true"
          id="create-feed" type="button" class="mr-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">
          CREATE
          <i class="fas fa-plus ml-2 -mr-1 h-4 w-4"></i>
        </button>
      </div>
    </PageHeader>
    <Modal :show.sync="showCreateNew">
      <span slot="header">Create Library</span>
      <Alert type="error" v-if="libCreateError">Something went wrong. Please try again!</Alert>
      <FormControl v-model="libraryForm.displayName" :error="validation.firstError('libraryForm.displayName')" :inline="true" label="Name"></FormControl>
      <FormControl v-model="libraryForm.description" :error="validation.firstError('libraryForm.description')" :inline="true" label="Description"></FormControl>
      <div slot="footer">
        <button class="btn-cancel" @click="showCreateNew = false">
          Cancel
        </button>
         <button type="submit" class="btn-action" :disabled="!libraryForm.displayName || loading" @click="createLibrary">
          Create
        </button>
      </div>
    </Modal>
    <div class="flex flex-col mt-5">
      <Libraries ref="list"></Libraries>
      <!-- <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name <br>Description
                  </th>
                  <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Document Count
                  </th>
                  <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Updated
                  </th>
                  <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Created
                  </th>
                  <th scope="col" class="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="lib in libraries" :key="lib.id">
                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="">
                        <div class="text-sm font-medium text-indigo-400 cursor-pointer">
                          {{lib.displayName}}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{lib.description}}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-3 py-4 whitespace-nowrap">
                    <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-200 text-yellow-800">
                      {{lib.documentsByLibraryId.totalCount}}
                    </span>
                  </td>
                  <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                    <timeago :datetime="new Date('2020-05-22')"></timeago>
                  </td>
                  <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                    <timeago :datetime="new Date('2021-01-22')"></timeago>
                  </td>
                  <td class="px-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <a-popconfirm
                      title="Are you sure delete this library?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="deleteLib"
                    >
                      <button type="button" class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <svg class="h-5 w-5 text-red-400 inline-block cursor-pointer" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                      </button>
                    </a-popconfirm>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  data() {
    return {
      showCreateNew: false,
      showFilters: false,
      libs: [],
      libraryForm: {
        displayName: '',
        description: '',
      },
      libCreateError: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      libraries: (state) => state.library.list,
      docCount: (state) => state.library.count,
      customer: (state) => state.auth.customerId,
      customers: (state) => state.customer.list,
    }),
  },
  validators: {
    // eslint-disable-next-line
    'libraryForm.displayName': (value) => Validator.value(value).required(),
  },
  watch: {
    customers() {
      console.log('watch customers', this.customers);
    },
  },
  mounted() {
    console.group('LIBRARIES LIST');
    console.log('-> LIBRARIES LIST');
    this.init();
  },
  beforeDestroy() {
    console.groupEnd('LIBRARIES LIST');
  },
  methods: {
    ...mapActions({
      fetchLibrary: 'library/fetchLibrary',
      fetch: 'library/fetch',
      create: 'library/create',
      delete: 'library/delete',
    }),
    async init() {
      // await this.fetch();
      // const
      // await this.fetchLibrary(id);
    },
    async createLibrary() {
      this.libCreateError = false;
      this.loading = true;
      const isValid = await this.$validate();
      console.log('createLibrary', isValid);
      if (!isValid) return;
      const payload = {
        ...this.libraryForm,
        // customerId: this.customer,
      };

      const result = await this.create(payload);
      console.log('createLibrary result', result);
      if (result.err) {
        this.libCreateError = true;
        this.loading = false;
        return;
      }
      this.showCreateNew = false;
      this.loading = false;
      this.$message.success('Library created');
      this.libraryForm = {
        displayName: '',
        description: '',
      };
      await this.$refs.list.refresh();
    },
    closeFilters() {
      this.showFilters = false;
    },
  },
};
</script>
