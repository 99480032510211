<template>
  <div class="">
    <PageHeader>
      <nav class="sm:flex -mb-6" aria-label="Breadcrumb">
        <ol class="flex items-center space-x-4">
          <li>
            <div class="flex items-center">
              <router-link :to="{ path: '/' + customer + '/libraries' }"  class="text-sm font-medium text-gray-400 hover:text-gray-200">Libraries</router-link>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <!-- Heroicon name: solid/chevron-right -->
              <svg class="flex-shrink-0 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
              </svg>
              <router-link :to="{ path: '/' + customer + '/libraries/' + libraryId + '/documents' }"  class="ml-4 text-sm font-medium text-gray-400 hover:text-gray-200">{{library.displayName}}</router-link>
            </div>
          </li>
        </ol>
      </nav>
      <div class="relative inline-block text-left" v-click-outside="closeDropdown">
        <div @click="showDropdown = !showDropdown" class="cursor-pointer">
          Documents
          <span class="flex-shrink-0 h-5 w-5 text-gray-500 text-sm relative -top-1">
            <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="showDropdown" />
            <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="!showDropdown" />
          </span>
        </div>
        <div class="origin-top-center absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5" :class="{hidden: !showDropdown}">
          <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <router-link :to="{ path: '/' + customer + '/libraries/' + libraryId + '/documents' }"  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">Documents</router-link>
            <router-link :to="{ path: '/' + customer + '/libraries/' + libraryId + '/feeds' }"  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">Feeds</router-link>
            <router-link :to="{ path: '/' + customer + '/libraries/' + libraryId + '/indices' }"  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">Indices</router-link>
          </div>
        </div>
      </div>

      <div class="mt-4 flex md:mt-0 md:ml-4" slot="actions"
          v-click-outside="closeFilters">
        <button
          @click="showCreateNew = true"
          id="create-feed" type="button" class="mr-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">
          Upload
          <i class="fas fa-upload ml-2 -mr-1 h-4 w-4"></i>
        </button>
      </div>
    </PageHeader>
    <div class="flex flex-col mt-5">
      <Documents :libraryId="libraryId" ref="documents"></Documents>
    </div>
    <Modal :show.sync="showCreateNew">
      <span slot="header">Upload document</span>
        <div class="sm:pt-5">
          <div class="w-full">
            <a-upload-dragger
              name="file"
              :remove="handleRemove"
              :multiple="true"
              accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .html, .txt,
                .ogg, .wav, .mp3, .mp4, .m4a, .m4p, .m4b, .m4r, .m4v, .m1v, .ogg, .mov, .qt, .webm, .asf, .wma, .wmv, .avi"
              :beforeUpload="beforeUpload"
              :fileList="fileList"
            >
              <p class="ant-upload-drag-icon">
                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </p>
              <p class="ant-upload-text">
                <span class="font-medium text-indigo-600 ">Click or drag file</span> to this area to upload
              </p>
              <p class="ant-upload-hint">
                <!-- DOCX, PDF, HTML, TXT up to 10MB -->
              </p>
            </a-upload-dragger>
          </div>
      </div>
      <div slot="footer">
        <button class="btn-cancel" @click="showCreateNew = false">
          Cancel
        </button>
         <button type="submit" class="btn-action" :disabled="fileList.length === 0" @click="uploadDocs" :class="{loading: uploading}">
          Upload
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

const Jabber = require('jabber');

export default {
  data() {
    return {
      showCreateNew: false,
      showFilters: false,
      showDropdown: false,
      gen: new Jabber(),
      libs: [],
      libraryId: null,
      fileList: [],
      uploading: false,
      libraryForm: {
        displayName: '',
        description: '',
      },
      headers: {},
    };
  },
  computed: {
    ...mapState({
      libraries: (state) => state.library.list,
      library: (state) => state.library.selected,
      docCount: (state) => state.library.count,
      customer: (state) => state.auth.customerId,
      customers: (state) => state.customer.list,
    }),
  },
  validators: {
    // eslint-disable-next-line
    'libraryForm.displayName': (value) => Validator.value(value).required(),
  },
  watch: {
    customers() {
      console.log('watch customers', this.customers);
    },
  },
  created() {
    this.libraryId = this.$route.params.libraryId;
  },
  mounted() {
    console.group('LIBRARIES');
    console.log('-> LIBRARIES');
    this.init();
  },
  beforeDestroy() {
    console.groupEnd('LIBRARIES');
  },
  methods: {
    ...mapActions({
      fetchLibrary: 'library/fetchLibrary',
      create: 'library/create',
      delete: 'library/delete',
      upload: 'documents/upload',
    }),
    async init() {
      await this.fetchLibrary(this.libraryId);
    },
    async createLibrary() {
      const isValid = await this.$validate();
      console.log('createLibrary', isValid);
      if (!isValid) return;
      const payload = {
        ...this.libraryForm,
        // customerId: this.customer,
      };

      const result = await this.create(payload);
      if (result.err) return;
      this.showCreateNew = false;
      this.libraryForm = {
        displayName: '',
        description: '',
      };
      await this.fetch();
    },
    closeFilters() {
      this.showFilters = false;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    async uploadDocs() {
      console.log('upload doc', this.fileList);
      this.uploading = true;
      await this.asyncForEach(this.fileList, async (file, index) => {
        const params = {
          body: file,
          name: file.name,
          type: file.type,
          directory: this.libraryId,
          libraryId: this.libraryId,
        };
        const result = await this.upload(params);
        if (result) {
          this.fileList[index].status = 'success';
        }
      });
      setTimeout(() => {
        this.$refs.documents.refresh();
      }, 200);
      this.uploading = false;
      this.showCreateNew = false;
      this.fileList = [];
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      console.log('fileList', file);
      this.fileList = [...this.fileList, file];
      return false;
    },
    handleChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array); // eslint-disable-line
      }
    },
  },
};
</script>
