<template>
  <div>
    <a :href="downloadItem.url" ref="download" class="hidden" :download="downloadItem.name">{{downloadItem.name}}</a>
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="w-full divide-y divide-gray-200" :class="{loading: loading}">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" width="240" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-28" @click="orderBy('NAME')">
                  Name <br>Directory
                  <span v-show="selectedOrder === 'NAME'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th @click="orderBy('CONTENT_TYPE')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Document Type
                  <span v-show="selectedOrder === 'CONTENT_TYPE'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th @click="orderBy('UPDATED_AT')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Updated
                  <span v-show="selectedOrder === 'UPDATED_AT'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th @click="orderBy('CREATED_AT')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Created
                  <span v-show="selectedOrder === 'CREATED_AT'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th @click="orderBy('PROCESSING_STAGE')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Processing stage
                  <span v-show="selectedOrder === 'PROCESSING_STAGE'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th @click="orderBy('PROCESSING_STATUS')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Processing Status
                  <span v-show="selectedOrder === 'PROCESSING_STATUS'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th scope="col" class="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="item in list" :key="item.id">
                <td class="px-3 py-4 whitespace-nowrap w-28">
                  <div class="flex items-center">
                    <div class="w-48" @click="select(item)" style="width: 240px">
                      <div class="text-sm font-medium text-indigo-400 cursor-pointer break-all w-48 whitespace-pre-wrap">{{item.name}}
                      </div> <span class="font-bold">in {{item.libraryByLibraryId.displayName}}</span>
                      <div class="text-sm text-gray-500">
                        {{item.directory}}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-3 py-4 whitespace-nowrap">
                  <span class="type break-all whitespace-pre-wrap"
                    :style="tagStyle(item.contentType)"
                    :class="item.contentType ? item.contentType.replace('\/', '-').replace('.', '-') : ''">
                    {{item.contentType || 'unknown'}}
                  </span>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <timeago :datetime="moment.utc(item.updatedAt)"></timeago>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <timeago :datetime="moment.utc(item.createdAt)"></timeago>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{item.processingStage}}
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm"
                  :class="item.processingStatus">
                  <span class="status h-4 w-4 rounded-full flex items-center justify-center" aria-hidden="true">
                    <span class="h-2 w-2 rounded-full"></span>
                  </span>
                  <span class="">{{item.processingStatus}}</span>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <a-tooltip title="Download document">
                    <button type="button"
                      @click="download(item)"
                      class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
                      :class="{loading: item.loading}">
                      <!-- Heroicon name: solid/plus -->
                      <svg class="h-5 w-5 text-indigo-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd"></path></svg>
                    </button>
                  </a-tooltip>
                  <a-tooltip title="Delete document">
                    <a-popconfirm
                      title="Are you sure delete this document?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="deleteItem(item)"
                    >
                      <button type="button" class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <svg class="h-5 w-5 text-red-400 inline-block cursor-pointer" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                      </button>
                    </a-popconfirm>
                  </a-tooltip>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-7 mb-7" v-show="list.length === 0 && !loading">
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
          <svg
            class="h-6 w-6 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            There is no documents
          </h3>
          <div class="mt-2">
          </div>
        </div>
      </div>
      <!-- PAGINA -->
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <pagination v-model="page" :records="totalCount" @paginate="loadData"/>
      </div>
    </div>
    <!-- <infinite-loading spinner="waveDots" @infinite="loadMore"></infinite-loading> -->
    <Modal :show.sync="showDetails">
      <span slot="header">Document Details</span>
        <div class="sm:pt-5">
          <div class="w-full">
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="bg-white overflow-hidden sm:rounded-lg -mx-6 -mt-0">
              <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-gray-200">
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Name
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.name}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Directory
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.directory}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Library
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDocLibrary(selectedDoc)}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Content Type
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span class="type"
                        :class="selectedDoc.contentType ? selectedDoc.contentType.replace('\/', '-') : ''">
                        {{selectedDoc.contentType}}
                      </span>
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Content hash
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.contentHash}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Original URI
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.originalUri}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Created at <br>(updated at)
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <timeago :datetime="moment.utc(selectedDoc.createdAt)" v-if="selectedDoc.createdAt"></timeago> (<timeago :datetime="moment.utc(selectedDoc.updatedAt)" v-if="selectedDoc.updatedAt"></timeago>)
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Processing Stage
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.processingStage}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Processing Status
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div
                        :class="selectedDoc.processingStatus">
                        <span class="status h-4 w-4 rounded-full flex items-center justify-center" aria-hidden="true">
                          <span class="h-2 w-2 rounded-full"></span>
                        </span>
                        <span>{{selectedDoc.processingStatus}}</span>
                      </div>
                      <div class="text-red-500 text-xs mt-2">
                        {{selectedDoc.processingError}}
                      </div>
                    </dd>
                  </div>
                </dl>
                <div class="hidden sm:block">
                  <div class="pl-4">
                    <nav class="flex space-x-4" aria-label="Tabs">
                      <a @click="activeTab = 1" class="tab" :class="{active: activeTab === 1}">
                        Metadata
                      </a>
                      <a @click="activeTab = 2" class="tab" :class="{active: activeTab === 2}">
                        Indices State
                      </a>
                    </nav>
                    <div class="content mr-4 mt-3" v-if="activeTab === 1">
                      <div class="mt-7 mb-7" v-if="Object.keys(selectedDoc.metadata).length === 0">
                        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                          <svg
                            class="h-6 w-6 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div class="mt-3 text-center sm:mt-5">
                          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            No metadata
                          </h3>
                          <div class="mt-2">
                          </div>
                        </div>
                      </div>
                      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200" v-if="Object.keys(selectedDoc.metadata).length !== 0">
                        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                          v-for="(data, key) in selectedDoc.metadata" :key="key">
                          <div class="w-0 flex-1 flex items-center">
                            <span class="ml-2 flex-1 w-0 truncate uppercase">
                              {{key.replace(/([a-z])([A-Z])/g, '$1 $2')}}
                            </span>
                          </div>
                          <div class="ml-4 flex-shrink-0 break-all whitespace-pre-wrap" style="max-width:200px">
                            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">{{data}}
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="content mr-4 mt-3" v-if="activeTab === 2">
                      <div class="mt-7 mb-7" v-if="selectedDocIndex.length === 0">
                        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                          <svg
                            class="h-6 w-6 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div class="mt-3 text-center sm:mt-5">
                          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            No related induces
                          </h3>
                          <div class="mt-2">
                          </div>
                        </div>
                      </div>
                      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200" v-if="selectedDocIndex.length !== 0">
                        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                          v-for="(data, key) in selectedDocIndex" :key="key">
                          <div class="w-0 flex-1 flex items-center">
                            <span class="ml-2 flex-1 w-0 truncate uppercase">
                              {{data.indexByIndexId.type}}
                            </span>
                          </div>
                          <div class="ml-4 flex-shrink-0">
                            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500 overflow-ellipsis overflow-hidden">
                              {{data.state}}
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
      </div>
      <div slot="footer">
         <button type="submit" class="btn-action" @click="showDetails = false">
          OK
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
// import { saveAs } from 'file-saver';
// const download = require('downloadjs');

export default {
  name: 'Documents',
  props: {
    libraryId: String,
  },
  data() {
    return {
      selectedOrder: 'NAME',
      orderDir: false, // false = asc, true = desc
      pageCount: 0,
      pageSize: 25,
      page: 1,
      offset: 0,
      loading: true,
      showDetails: false,
      activeTab: 1,
      selectedDoc: {
        metadata: {},
      },
      downloadItem: {},
      moment,
    };
  },
  computed: {
    ...mapState({
      list: (state) => state.documents.list,
      hasNextPage: (state) => state.documents.hasNextPage,
      totalCount: (state) => state.documents.count,
    }),
    numberOfPages() {
      return Math.ceil(this.totalCount / this.pageSize);
    },
    orderDirString() {
      return this.orderDir ? 'DESC' : 'ASC';
    },
    orderByString() {
      return `${this.selectedOrder}_${this.orderDirString}`;
    },
    selectedDocLibrary() {
      return (doc) => {
        console.log('doc', doc);
        return doc && doc.libraryByLibraryId ? doc.libraryByLibraryId.displayName : 'not set';
      };
    },
    selectedDocIndex() {
      if (!this.selectedDoc || !this.selectedDoc.documentIndexStatesByDocumentId) return {};
      return this.selectedDoc.documentIndexStatesByDocumentId.nodes;
    },
    tagStyle() {
      return (text) => {
        if (text) {
          return {
            // width: `${Math.min((text.length + 2), 24)}ch`,
          };
        }
        return {};
      };
    },
    queryParams() {
      const input = {
        first: 25,
        orderBy: this.orderByString,
        offset: this.offset,
        condition: {
          isDeleted: false,
        },
      };

      if (this.libraryId) {
        input.condition.libraryId = this.libraryId;
      }

      return input;
    },
  },
  mounted() {
    this.clearList();
    this.loadData(1);
  },
  methods: {
    ...mapMutations({
      clearList: 'documents/clearList',
    }),
    ...mapActions({
      fetch: 'documents/fetch',
      create: 'documents/create',
      deleteDoc: 'documents/deleteDoc',
      generateUrl: 'documents/generateUrl',
    }),
    async deleteItem(item) {
      console.log('delete Doc', item);
      this.$emit('deleteItem', item);
      const result = await this.deleteDoc(item.id);
      console.log('deleted', result);
      if (!result.ok || result.error) {
        this.$message.error('Something went wrong');
        return;
      }
      await this.refresh();
      this.$message.success('Document deleted');
      this.$emit('deleteItem', item);
    },
    async download(item) {
      // eslint-disable-next-line
      item.loading = true;
      console.log('DOWNLOAD DOCUMENT', item);
      const result = await this.generateUrl({
        documentId: item.id,
        libraryId: item.libraryId,
      });
      if (!result.ok) {
        // eslint-disable-next-line
        item.loading = false;
        this.$message.error('Something went wrong. Can`t generate URL for selected document');
        return;
      }
      this.downloadItem = item;
      this.downloadItem.url = result.url;
      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);
      // eslint-disable-next-line
      item.loading = false;

      // Set the HREF to a Blob representation of the data to be downloaded
      a.href = result.url;

      // Use download attribute to set set desired file name
      a.setAttribute('download', item.name);
      a.setAttribute('target', '_blank');

      // Trigger the download by simulating click
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
      //  saveAs
    },
    async orderBy(type) {
      this.loading = true;
      if (type === this.selectedOrder) {
        this.orderDir = !this.orderDir;
        this.selectedOrder = type;
        await this.loadData(this.page);
        this.loading = false;
        return;
      }
      this.orderDir = false;
      this.selectedOrder = type;
      await this.loadData(this.page);
      this.loading = false;
    },
    async refresh() {
      this.loadData(this.page);
    },
    async loadData(page) {
      this.loading = true;
      console.log('page', page);
      this.offset = page * this.pageSize - this.pageSize;
      await this.fetch({ payload: this.queryParams, loadMore: false });
      this.loading = false;
    },
    select(item) {
      this.selectedDoc = item;
      this.showDetails = true;
    },
  },
};
</script>
