<template>
  <div class="">
    <PageHeader>
      Feeds
      <div class="mt-4 flex md:mt-0 md:ml-4" slot="actions"
          v-click-outside="closeFilters">
        <button
          @click="showCreateNew = true"
          id="create-feed" type="button" class="mr-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">
          CREATE
          <i class="fas fa-plus ml-2 -mr-1 h-4 w-4"></i>
        </button>
      </div>
    </PageHeader>
    <div class="flex flex-col mt-5">
      <Feeds ref="feeds"></Feeds>
    </div>
    <!-- CREATE FEED -->
    <Modal :show.sync="showCreateNew">
      <span slot="header">Create Feed</span>
        <div class="sm:pt-5">
          <div class="w-full">
            <Alert type="error" v-if="createError">Something went wrong. Please try again!</Alert>
            <FormControl v-model="newFeed.displayName" :error="validation.firstError('newFeed.displayName')" :inline="true" label="Display Name"></FormControl>
            <FormControl v-model="newFeed.description" :error="validation.firstError('newFeed.description')" :inline="true" label="Description"></FormControl>
            <FormControlCustom :error="validation.firstError('newFeed.type')" :inline="true" type="text" label="Type">
              <a-select
                style="width: 100%"
                placeholder="Please select"
                v-model="newFeed.type"
              >
                <a-select-option v-for="opt in types" :key="opt.id" :value="opt.id">
                  {{ opt.name }}
                </a-select-option>
              </a-select>
            </FormControlCustom>
            <FormControlTextarea v-model="newFeed.metadata" :error="validation.firstError('newFeed.metadata')" :inline="true" type="textarea" label="Metadata"></FormControlTextarea>
            <!-- <FormControlSelect v-model="newFeed.supportedExtensions" :error="validation.firstError('newFeed.indexSettings')" :inline="true" type="textarea" :options="extensions" label="Supported Extensions"></FormControlSelect> -->
            <!-- <FormControlSelect v-model="newFeed.supportedExtensions" :error="validation.firstError('newFeed.supportedExtensions')" :inline="true" type="textarea" :options="extensions" label="Supported Extensions"></FormControlSelect> -->
            <FormControlCustom :error="validation.firstError('newFeed.supportedExtensions')" :inline="true" type="text" label="Supported Extensions">
              <a-select
                mode="multiple"
                style="width: 100%"
                placeholder="Please select"
                v-model="newFeed.supportedExtensions"
              >
                <a-select-option v-for="opt in extensions" :key="opt" :value="opt">
                  {{ opt }}
                </a-select-option>
              </a-select>
            </FormControlCustom>
            <div class="relative my-4">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300"></div>
              </div>
              <div class="relative flex justify-end">
                <span class="pl-2 bg-white text-sm text-gray-500">
                  LIBRARY
                </span>
              </div>
            </div>
            <div>
              <div class="">
                <nav class="flex space-x-4" aria-label="Tabs">
                  <a @click="createNewLib = false" :class="{'bg-indigo-100': !createNewLib}" class="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md">
                    Add to existing library
                  </a>
                  <a @click="createNewLib = true" :class="{'bg-indigo-100': createNewLib}" class="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md">
                    Create new library
                  </a>
                </nav>
                <div class="content mr-4 mt-3" v-if="!createNewLib">
                  <FormControlCustom :inline="true" type="text" label="Library">
                    <a-select
                      style="width: 100%"
                      placeholder="Please select"
                      v-model="newFeed.libraryId"
                    >
                      <a-select-option v-for="opt in libraries" :key="opt.id" :value="opt.id">
                        {{ opt.displayName }}
                      </a-select-option>
                    </a-select>
                  </FormControlCustom>
                </div>
                <div class="content mr-4 mt-3" v-if="createNewLib">
                  <FormControl v-model="newFeed.libraryDisplayName" :error="validation.firstError('newFeed.libraryDisplayName')" :inline="true" label="Library name"></FormControl>
                  <FormControl v-model="newFeed.libraryDescription" :error="validation.firstError('newFeed.libraryDescription')" :inline="true" label="Library Description"></FormControl>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div slot="footer">
        <button class="btn-cancel" @click="showCreateNew = false">
          Cancel
        </button>
         <button type="submit" class="btn-action" @click="createFeed" :disabled="!newFeed.displayName || !newFeed.type">
          Create
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  data() {
    return {
      showFilters: false,
      showCreateNew: false,
      extensions: ['docx', 'pdf', 'txt', 'html'],
      types: [
        {
          id: 'google_drive',
          name: 'Google Drive',
        },
      ],
      newFeed: {
        customerId: null,
        displayName: null,
        description: '',
        type: null,
        libraryId: null,
        libraryDisplayName: null,
        libraryDescription: null,
        supportedExtensions: [],
      },
      createNewLib: false,
      createError: false,
    };
  },
  computed: {
    ...mapState({
      libraries: (state) => state.library.list,
      feeds: (state) => state.feed.list,
      count: (state) => state.feed.count,
      customer: (state) => state.auth.customerId,
      userEmail: (state) => state.auth.userEmail,
      userProfile: (state) => state.auth.userProfile,
    }),
    libQueryParams() {
      const input = {
        first: 125,
        offset: 0,
        condition: {
          isDeleted: false,
        },
      };
      return input;
    },
  },
  validators: {
    // eslint-disable-next-line
    'newFeed.displayName': (value) => Validator.value(value).required(),
    'newFeed.type': (value) => Validator.value(value).required(),
  },
  mounted() {
    console.group('FEED');
    console.log('-> FEED');
    this.fetchLibraries({ payload: this.libQueryParams, loadMore: false });
  },
  beforeDestroy() {
    console.groupEnd('FEED');
  },
  methods: {
    ...mapActions({
      fetchLibraries: 'library/fetch',
      create: 'feed/create',
      delete: 'feed/delete',
      attach: 'feed/attach',
    }),
    closeFilters() {
      this.showFilters = false;
    },
    async createFeed() {
      this.createError = false;
      const isValid = await this.$validate();
      console.log('create', isValid);
      if (!isValid) return;
      const payload = {
        ...this.newFeed,
        // createdBy: this.userProfile.id,
        customerId: this.customer,
        // customerId: this.customer,
      };
      if (payload.libraryId) {
        delete payload.libraryDescription;
        delete payload.libraryDisplayName;
      } else {
        delete payload.libraryId;
      }
      payload.type = payload.type.toLowerCase();
      const result = await this.create(payload);
      console.log('result', result);
      if (!result.ok) {
        this.createError = true;
        return;
      }
      this.showCreateNew = false;
      this.newFeed = {
        libraryId: this.libraryId,
        indexType: null,
        createdBy: this.userEmail,
      };
      window.location.href = result.signInUrl;
      await this.$refs.feeds.refresh();
    },
  },
};
</script>
