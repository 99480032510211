import Vue from 'vue';
import VueRouter from 'vue-router';
import a404 from '../views/404.vue';
import Dashboard from '../views/Dashboard.vue';
import Search from '../views/Search.vue';
import Wrapper from '../views/Wrapper.vue';
import Feeds from '../views/Feeds.vue';
import Documents from '../views/Documents.vue';

import Libraries from '../views/Libraries.vue';
import LibrariesDocuments from '../views/LibrariesDocuments.vue';
import LibrariesFeeds from '../views/LibrariesFeeds.vue';
import LibrariesList from '../views/LibrariesList.vue';
import LibrariesIndices from '../views/LibrariesIndices.vue';

// USER
import UserProfile from '../views/UserProfile.vue';

// AUTH
import Login from '../views/AuthLogin.vue';
import Register from '../views/AuthRegister.vue';
import Reset from '../views/AuthReset.vue';

import Root from '../views/Root.vue';

import isAuthenticated from '../guards/isAuthenticated';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:customer',
    component: Wrapper,
    children: [
      {
        path: '/',
        redirect: 'dashboard',
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'search',
        name: 'Search',
        component: Search,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'documents',
        name: 'Documents',
        component: Documents,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'feeds',
        name: 'Feeds',
        component: Feeds,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'libraries',
        name: 'Libraries',
        component: Libraries,
        beforeEnter: isAuthenticated,
        children: [
          {
            path: 'list',
            name: 'LibrariesList',
            component: LibrariesList,
          },
          {
            path: ':libraryId/documents',
            name: 'LibrariesDocuments',
            component: LibrariesDocuments,
          },
          {
            path: ':libraryId/feeds',
            name: 'LibrariesFeeds',
            component: LibrariesFeeds,
          },
          {
            path: ':libraryId/indices',
            name: 'LibrariesIndices',
            component: LibrariesIndices,
          },
          {
            path: '',
            component: LibrariesList,
          },
        ],
      },
      {
        path: 'user-profile',
        name: 'UserProfile',
        component: UserProfile,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'default',
        name: 'Root',
        component: Root,
      },
    ],
  },
  {
    path: '/',
    component: Root,
  },
  {
    path: '/:customer/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/:customer/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/:customer/resetpassword',
    name: 'Reset',
    component: Reset,
  },
  {
    path: '*',
    name: '404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: a404,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
