/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../graphql';

const state = {
  name: 'Customers',
  loading: false,
  list: [],
  currentCustomer: {},
  customerId: null,
  count: 0,
  loadings: {
  },
};

const getters = {
};

const mutations = {
  setList: (state, payload) => {
    Vue.set(state, 'list', payload);
    payload.forEach((element) => {
      console.log('el', state.customerId, element);
      if (element.id === state.customerId) {
        Vue.set(state, 'currentCustomer', element);
      }
    });
  },

  setCustomerId: (state, payload) => {
    Vue.set(state, 'customerId', payload);
  },
  setCount: (state, payload) => {
    Vue.set(state, 'count', payload);
  },

  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },
};

const actions = {
  async fetch({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllCustomers, {
          first: payload || 25,
        }),
      );
      const { nodes } = response.data.allCustomers;
      commit('setList', nodes);
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(this._vm);
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log('err', error, payload);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchCustomer({ commit }, payload) {
    // console.log('fetch loadMore', loadMore);
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CustomerById, payload),
      );
      const { customerById } = response.data;
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(customerById);
      commit('setLoadings', 'value');
      return customerById;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log('err', error, payload);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
