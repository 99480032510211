<template>
  <div>
    <div class="max-w-3xl mx-auto md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl mb-5">
      <div class="flex items-center space-x-5">
        <div>
          <h1 class="text-2xl font-bold text-gray-900">{{this.userEmail}}</h1>
          <p class="text-sm font-medium text-gray-500">{{customer}}</p>
        </div>
      </div>
      <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Profile</h3>
          <p class="mt-1 text-sm text-gray-500">Lorem ipsum sit dolor</p>
        </div>

        <div class="grid grid-cols-3 gap-6">
          <div class="col-span-3 sm:col-span-2">
            <label for="company_website" class="block text-sm font-medium text-gray-700">
              Display Name
            </label>
            <div class="mt-1 rounded-md shadow-sm flex">
              <input type="text" name="username" id="username"
              v-model="userProfile.displayName"
              autocomplete="username" class="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300">
            </div>
          </div>

          <div class="col-span-3">
            <label for="about" class="block text-sm font-medium text-gray-700">
              Current role
            </label>
            <div class="mt-1">
              <input :value="userRole" type="text" name="userRole" id="userRole" autocomplete="userRole" class="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled" disabled>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button type="submit" class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          @click="save" :class="{loading: loading}">
          Save
        </button>
      </div>
    </div>
    <div class="shadow sm:rounded-md sm:overflow-hidden mt-4">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Password change</h3>
          <p class="mt-1 text-sm text-gray-500">Lorem ipsum sit dolor</p>
        </div>

        <div class="grid grid-cols-3 gap-6">
          <div class="col-span-3 sm:col-span-2">
            <label for="password"
              class="block text-sm font-medium">
              Old password
            </label>
            <div class="mt-1 relative">
              <input v-model="oldPassword"
              id="oldPassword" name="oldPassword" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                v-if="oldPassword && validation.hasError('oldPassword')">
                <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
            <div class="message text-red-500 text-xs mt-1" v-if="validation.isTouched('oldPassword')">
              {{ validation.firstError('oldPassword') }}
              <i v-if="validation.isValidating('oldPassword')" class="fa fa-spinner fa-spin"></i>
              <i v-if="oldPassword && validation.isPassed('oldPassword')" class="text-success fa fa-check-circle"></i>
            </div>
          </div>
          <div class="col-span-3 sm:col-span-2">
            <label for="password"
              class="block text-sm font-medium">
              New password
            </label>
            <div class="mt-1 relative">
              <input v-model="password"
              id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                v-if="password && validation.hasError('password') && validation.isTouched('password')">
                <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
            <div class="message text-red-500 text-xs mt-1" v-if="validation.isTouched('password')">
              {{ validation.firstError('password') }}
              <i v-if="validation.isValidating('password')" class="fa fa-spinner fa-spin"></i>
              <i v-if="password && validation.isPassed('password')" class="text-success fa fa-check-circle"></i>
            </div>
          </div>
          <div class="col-span-3 sm:col-span-2">
            <label for="password"
              class="block text-sm font-medium">
              Confirm Password
            </label>
            <div class="mt-1 relative">
              <input
              v-model="confirmPassword"
              id="confirmPassword" name="confirmPassword" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                v-if="confirmPassword && validation.hasError('confirmPassword') && validation.isTouched('confirmPassword')">
                <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
            <div class="message text-red-500 text-xs mt-1" v-if="validation.isTouched('confirmPassword')">
              {{ validation.firstError('confirmPassword') }}
              <i v-if="validation.isValidating('confirmPassword')" class="fa fa-spinner fa-spin"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button type="submit" class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          @click="resetPassword" :class="{loading: isBtnLoading, 'opacity-50': !oldPassword || !password || !confirmPassword}" :disabled="!oldPassword || !password || !confirmPassword">
          Change Password
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  data() {
    return {
      user: null,
      searchString: '',
      showDropdown: false,
      showMobileNav: false,
      loading: false,
      confirmPassword: null,
      password: null,
      oldPassword: null,
      isBtnLoading: false,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userEmail: (state) => state.auth.userEmail,
      userProfile: (state) => state.auth.userProfile,
      customer: (state) => state.auth.customer,
      userRole: (state) => state.auth.userRole,
      customers: (state) => state.customer.list,
    }),
    avatar() {
      return `https://avatars.dicebear.com/api/bottts/${this.userEmail}.svg`;
    },
  },
  validators: {
    oldPassword: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    password: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    // eslint-disable-next-line
    'confirmPassword, password': function(confirmPassword, password) {
      console.log('sss');
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword).required().match(password);
      }
      return false;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapMutations({
      setSession: 'auth/setSession',
      setCustomer: 'auth/setCustomer',
      setIsLoggedIn: 'auth/setIsLoggedIn',
    }),
    ...mapActions({
      fetch: 'customer/fetch',
      update: 'auth/updateUser',
    }),

    async save() {
      this.loading = true;
      // userProfile.displayName
      const result = await this.update({ userId: this.userProfile.id, displayName: this.userProfile.displayName });

      if (!result.ok) {
        this.$message.error('Something went wrong.');
        return;
      }

      this.loading = false;
    },

    async resetPassword(data) {
      const isValid = await this.$validate();
      this.errorMessage = null;
      console.log('resetPassword', isValid);
      if (!isValid) return;
      this.loading = true;
      try {
        this.isBtnLoading = true;
        console.log('data', data);
        const user = await this.$Amplify.Auth.currentAuthenticatedUser();
        await this.$Amplify.Auth.changePassword(
          user,
          this.oldPassword,
          this.password,
        );
        this.isBtnLoading = false;
        this.$message.success(this.$t('account.forms.passwordChanged'));
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  },
};
</script>
