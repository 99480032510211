<template>
  <div class="">
    <PageHeader>Dashboard</PageHeader>

    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
      <div class="flex flex-col bg-white overflow-hidden shadow rounded-lg">
        <div class="flex-grow px-4 py-5 sm:p-6">
          <div class="flex items-center">
            <div class="flex-shrink-0 bg-green-400 rounded-md p-3">
              <svg
                class="h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
            </div>
            <div class="ml-5 w-0 flex-1">
              <dt class="text-sm font-medium text-gray-500 truncate">
                Total number of documents
              </dt>
              <dd class="flex items-baseline">
                <div class="text-2xl font-semibold text-gray-900">
                  {{docCount}}
                </div>
              </dd>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col bg-white overflow-hidden shadow rounded-lg">
        <div class="flex-grow px-4 py-5 sm:p-6">
          <div class="flex items-center">
            <div class="flex-shrink-0 bg-yellow-500 rounded-md p-3">
              <svg
                class="h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
              </svg>
            </div>
            <div class="ml-5 w-0 flex-1">
              <dt class="text-sm font-medium text-gray-500 truncate">
                Total number of libraries
              </dt>
              <dd class="flex items-baseline">
                <div class="text-2xl font-semibold text-gray-900">
                  {{libCount}}
                </div>
              </dd>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col bg-white overflow-hidden shadow rounded-lg">
        <div class="flex-grow px-4 py-5 sm:p-6">
          <div class="flex items-center">
            <div class="flex-shrink-0 bg-blue-500 rounded-md p-3">
              <!-- Heroicon name: outline/cursor-click -->
              <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
              </svg>
            </div>
            <div class="ml-5 w-0 flex-1">
              <dt class="text-sm font-medium text-gray-500 truncate">
                Total number of indices
              </dt>
              <dd class="flex items-baseline">
                <div class="text-2xl font-semibold text-gray-900">
                  {{indCount}}
                </div>
              </dd>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col bg-white overflow-hidden shadow rounded-lg">
        <div class="flex-grow px-4 py-5 sm:p-6">
          <div class="flex items-center">
            <div class="flex-shrink-0 bg-indigo-500 rounded-md p-3">
              <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
              </svg>
            </div>
            <div class="ml-5 w-0 flex-1">
              <dt class="text-sm font-medium text-gray-500 truncate">
                Total space used
              </dt>
              <dd class="flex items-baseline">
                <div class="text-2xl font-semibold text-gray-900">
                  {{indUsedSpace | prettyBytes }}
                </div>
              </dd>
            </div>
          </div>
        </div>
      </div>
    </dl>
    <h3 class="text-lg leading-6 font-medium text-gray-900 mt-10">
      Recently indexed documents
    </h3>
    <div class="bg-white shadow overflow-hidden sm:rounded-md mt-4">
      <div class="mt-7 mb-7" v-show="indexed.length === 0">
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
          <svg
            class="h-6 w-6 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            There is no indexed documents
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
            </p>
          </div>
        </div>
      </div>
      <ul class="divide-y divide-gray-200">
        <li v-for="doc in indexed" :key="doc.id" @click="openDocdetails(doc)">
          <a href="#" class="block hover:bg-gray-50">
            <div class="px-4 py-4 sm:px-6">
              <div class="flex items-center justify-between">
                <p class="text-md font-medium text-indigo-600 truncate mb-0">
                  {{doc.name}}
                </p>
                <div class="ml-2 flex-shrink-0 flex">
                  <span class="type"
                    :class="doc.contentType ? doc.contentType.replace('\/', '-') : ''">
                    {{doc.contentType}}
                  </span>
                </div>
              </div>
              <div class="mt-2 sm:flex sm:justify-between">
                <div class="sm:flex text-sm">
                  <p class="flex items-center text-sm text-gray-500; mr-2 mb-0">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" ><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"></path></svg>
                    {{doc.libraryByLibraryId.displayName}}
                  </p>
                  <p class="flex items-center text-sm text-gray-500 mb-0">
                    <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                    </svg>
                    Indexed <timeago :datetime="moment.utc(doc.updatedAt)" class="ml-2"></timeago>
                  </p>
                </div>
                <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                </div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
<Modal :show.sync="showDetails">
      <span slot="header">Document Details</span>
        <div class="sm:pt-5">
          <div class="w-full">
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="bg-white overflow-hidden sm:rounded-lg -mx-6 -mt-0">
              <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-gray-200">
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Name
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.name}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Directory
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.directory}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Library
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDocLibrary(selectedDoc)}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Content Type
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span class="type"
                        :class="selectedDoc.contentType ? selectedDoc.contentType.replace('\/', '-') : ''">
                        {{selectedDoc.contentType}}
                      </span>
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Content hash
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.contentHash}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Original URI
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.originalUri}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Created at <br>(updated at)
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <timeago :datetime="moment.utc(selectedDoc.createdAt)" v-if="selectedDoc.createdAt"></timeago> (<timeago :datetime="moment.utc(selectedDoc.updatedAt)" v-if="selectedDoc.updatedAt"></timeago>)
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Processing Stage
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{selectedDoc.processingStage}}
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Processing Status
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div
                        :class="selectedDoc.processingStatus">
                        <span class="status h-4 w-4 rounded-full flex items-center justify-center" aria-hidden="true">
                          <span class="h-2 w-2 rounded-full"></span>
                        </span>
                        <span>{{selectedDoc.processingStatus}}</span>
                      </div>
                      <div class="text-red-500 text-xs mt-2">
                        {{selectedDoc.processingError}}
                      </div>
                    </dd>
                  </div>
                </dl>
                <div class="hidden sm:block">
                  <div class="pl-4">
                    <nav class="flex space-x-4" aria-label="Tabs">
                      <a @click="activeTab = 1" class="tab" :class="{active: activeTab === 1}">
                        Metadata
                      </a>
                      <a @click="activeTab = 2" class="tab" :class="{active: activeTab === 2}">
                        Indices State
                      </a>
                    </nav>
                    <div class="content mr-4 mt-3" v-if="activeTab === 1">
                      <div class="mt-7 mb-7" v-if="Object.keys(selectedDoc.metadata).length === 0">
                        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                          <svg
                            class="h-6 w-6 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div class="mt-3 text-center sm:mt-5">
                          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            No metadata
                          </h3>
                          <div class="mt-2">
                          </div>
                        </div>
                      </div>
                      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200" v-if="Object.keys(selectedDoc.metadata).length !== 0">
                        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                          v-for="(data, key) in selectedDoc.metadata" :key="key">
                          <div class="w-0 flex-1 flex items-center">
                            <span class="ml-2 flex-1 w-0 truncate uppercase">
                              {{key.replace(/([a-z])([A-Z])/g, '$1 $2')}}
                            </span>
                          </div>
                          <div class="ml-4 flex-shrink-0 break-all whitespace-pre-wrap" style="max-width:200px">
                            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">{{data}}
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="content mr-4 mt-3" v-if="activeTab === 2">
                      <div class="mt-7 mb-7" v-if="selectedDocIndex.length === 0">
                        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                          <svg
                            class="h-6 w-6 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div class="mt-3 text-center sm:mt-5">
                          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            No related induces
                          </h3>
                          <div class="mt-2">
                          </div>
                        </div>
                      </div>
                      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200" v-if="selectedDocIndex.length !== 0">
                        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                          v-for="(data, key) in selectedDocIndex" :key="key">
                          <div class="w-0 flex-1 flex items-center">
                            <span class="ml-2 flex-1 w-0 truncate uppercase">
                              {{data.indexByIndexId.type}}
                            </span>
                          </div>
                          <div class="ml-4 flex-shrink-0">
                            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500 overflow-ellipsis overflow-hidden">
                              {{data.state}}
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
      </div>
      <div slot="footer">
         <button type="submit" class="btn-action" @click="showDetails = false">
          OK
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

const Jabber = require('jabber');

export default {
  data() {
    return {
      gen: new Jabber(),
      docs: [],
      selectedDoc: {
        metadata: {},
      },
      showDetails: false,
      activeTab: 1,
      moment,
    };
  },
  computed: {
    ...mapState({
      docCount: (state) => state.documents.count,
      libCount: (state) => state.library.count,
      indCount: (state) => state.indices.count,
      indUsedSpace: (state) => state.indices.usedSpace,
      indexed: (state) => state.documents.indexed,
    }),
    selectedDocLibrary() {
      return (doc) => {
        console.log('doc', doc);
        return doc && doc.libraryByLibraryId ? doc.libraryByLibraryId.displayName : 'not set';
      };
    },
    selectedDocIndex() {
      if (!this.selectedDoc || !this.selectedDoc.documentIndexStatesByDocumentId) return {};
      return this.selectedDoc.documentIndexStatesByDocumentId.nodes;
    },
  },
  mounted() {
    console.group('-> DASHBOARD');
    this.docs = [
      {
        id: 1,
        name: this.gen.createParagraph(5),
        contentType: 'application/octet-stream',
        libraryByLibraryId: {
          displayName: this.gen.createFullName(false),
        },
        indexedAt: new Date(),
      },
      {
        id: 2,
        name: this.gen.createParagraph(5),
        contentType: 'text/html',
        libraryByLibraryId: {
          displayName: this.gen.createFullName(false),
        },
        indexedAt: new Date(),
      },
      {
        id: 3,
        name: this.gen.createParagraph(5),
        contentType: 'text/plain',
        libraryByLibraryId: {
          displayName: this.gen.createFullName(false),
        },
        indexedAt: new Date(),
      },
      {
        id: 4,
        name: this.gen.createParagraph(5),
        contentType: 'application/pdf',
        libraryByLibraryId: {
          displayName: this.gen.createFullName(false),
        },
        indexedAt: new Date(),
      },
      {
        id: 5,
        name: this.gen.createParagraph(5),
        contentType: 'application/pdf',
        libraryByLibraryId: {
          displayName: this.gen.createFullName(false),
        },
        indexedAt: new Date(),
      },
      {
        id: 6,
        name: this.gen.createParagraph(5),
        contentType: 'text/html',
        libraryByLibraryId: {
          displayName: this.gen.createFullName(false),
        },
        indexedAt: new Date(),
      },
    ];
    this.getDocCount();
    this.getLibCount();
    this.getIndCount();
    this.fetchRecentlyIndexed();
    this.fetchDocuments({
      payload: {
        first: 25,
        offset: 0,
        condition: {
          isDeleted: false,
        },
      },
    });
  },
  beforeDestroy() {
    console.groupEnd('DASHBOARD');
  },
  methods: {
    ...mapActions({
      fetchDocuments: 'documents/fetch',
      getDocCount: 'documents/getCount',
      getLibCount: 'library/getCount',
      getIndCount: 'indices/getCount',
      fetchRecentlyIndexed: 'documents/fetchRecentlyIndexed',
    }),
    openDocdetails(doc) {
      this.selectedDoc = doc;
      this.showDetails = true;
      console.log('%cdoc', 'color: lime', doc);
    },
  },
};
</script>
