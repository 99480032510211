<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
<div class="lg:flex lg:items-center lg:justify-between">
  <div class="flex-1 min-w-0">
    <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl">
      <slot></slot>
    </h2>
    <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
      <slot name="description"></slot>
    </div>
  </div>
  <div class="mt-5 flex lg:mt-0 lg:ml-4">
    <slot name="actions"></slot>
  </div>
</div>

</template>
<script>
export default {
  name: 'PageHeader',
};
</script>
