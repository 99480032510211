/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../graphql';

const state = {
  name: 'Libraries',
  loading: false,
  list: [],
  selected: {},
  count: 0,
  loadings: {
  },
};

const getters = {
  getById: (state) => (id) => {
    const filteredLibs = state.list.filter((el) => el.id === id);
    if (filteredLibs.length > 0) return filteredLibs[0];
    return {};
  },
};

const mutations = {
  setList: (state, payload) => {
    if (payload.loadMore) {
      Vue.set(state, 'list', [
        ...state.list,
        ...payload.nodes,
      ]);
    } else {
      Vue.set(state, 'list', payload.nodes.filter((el) => !el.isDeleted));
    }
  },
  clearList: (state) => {
    Vue.set(state, 'list', []);
    Vue.set(state, 'hasNextPage', true);
  },
  setSelected: (state, payload) => {
    Vue.set(state, 'selected', payload);
  },
  setCount: (state, payload) => {
    Vue.set(state, 'count', payload);
  },
  setPageInfo: (state, payload) => {
    Vue.set(state, 'hasNextPage', payload.hasNextPage);
    Vue.set(state, 'pageInfo', payload);
  },

  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },
};

const actions = {
  async getCount({ commit }) {
    console.log('getCount');
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.LibrariesCount, {
          condition: {
            isDeleted: false,
          },
        }),
      );
      const { totalCount } = response.data.allLibraries;
      commit('setCount', totalCount);
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(this._vm);
      return totalCount;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log(error);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetch({ commit }, { payload, loadMore }) {
    console.log('fetch loadMore', loadMore);
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllLibraries, payload),
      );
      const { nodes, pageInfo, totalCount } = response.data.allLibraries;
      commit('setList', { nodes, loadMore });
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(this._vm);
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log('err', error, payload);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchLibrary({ commit }, payload) {
    console.log('fetchLibrary', payload);
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.Library, {
          id: payload,
        }),
      );
      const { libraryById } = response.data;
      commit('setSelected', libraryById);
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(this._vm);
      return libraryById;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log('err', error, payload);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async create({ commit }, payload) {
    commit('setLoadings', { type: 'library', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CreateLibrary, {
          input: payload,
        }),
      );
      const { createLibrary } = response.data;
      return createLibrary;
    } catch (error) {
      return { error: true, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'library', value: false });
    }
  },
  async delete({ commit }, payload) {
    commit('setLoadings', { type: 'library', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.DeleteLibrary, {
          input: {
            libraryId: payload,
          },
        }),
      );
      const { ok, err, libraryId } = response.data.deleteLibrary;
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(ok, err, libraryId);
      return response.data.deleteLibrary;
    } catch (error) {
      return { ok: false, err: error.errors, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'library', value: false });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
