import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import library from './library';
import search from './search';
import ui from './ui';
import documents from './documents';
import indices from './indices';
import feed from './feed';
import customer from './customer';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    library,
    search,
    ui,
    feed,
    indices,
    documents,
    customer,
  },
});
