/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import axios from 'axios';
import GQL from '../graphql';
import awsconfig from '../aws-exports';

const state = {
  name: 'Documents',
  loading: false,
  list: [],
  indexed: [],
  count: 0,
  hasNextPage: true,
  pageInfo: {},
  loadings: {
  },
};

const getters = {
};
const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});
const mutations = {
  setList: (state, payload) => {
    if (payload.loadMore) {
      Vue.set(state, 'list', [
        ...state.list,
        ...payload.nodes,
      ]);
    } else {
      Vue.set(state, 'list', payload.nodes
        .filter((el) => !el.isDeleted)
        .map((el) => ({ ...el, loading: false })));
    }
  },
  clearList: (state) => {
    Vue.set(state, 'list', []);
    Vue.set(state, 'hasNextPage', true);
  },
  setIndexed: (state, payload) => {
    const sorted = payload.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)).slice(0, 25);
    console.log('sorted', sorted);
    Vue.set(state, 'indexed', sorted);
  },
  setCount: (state, payload) => {
    Vue.set(state, 'count', payload);
  },
  setPageInfo: (state, payload) => {
    Vue.set(state, 'hasNextPage', payload.hasNextPage);
    Vue.set(state, 'pageInfo', payload);
  },

  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },
};

const actions = {
  async getCount({ commit }) {
    console.log('getCount');
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.DocumentCount, {
          condition: {
            isDeleted: false,
          },
        }),
      );
      const { totalCount } = response.data.allDocuments;
      commit('setCount', totalCount);
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(this._vm);
      return totalCount;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log(error);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchRecentlyIndexed({ commit }) {
    console.log('fetch loadMore');
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllDocuments, {
          condition: {
            processingStage: 'INDEXED',
            isDeleted: false,
          },
          orderBy: 'UPDATED_AT_ASC',
        }),
      );
      const response2 = await API.graphql(
        graphqlOperation(GQL.AllDocuments, {
          condition: {
            processingStage: 'INDEXING',
            processingStatus: 'IDLE',
            isDeleted: false,
          },
          orderBy: 'UPDATED_AT_ASC',
        }),
      );
      const { nodes } = response.data.allDocuments;
      commit('setIndexed', [...nodes, ...response2.data.allDocuments.nodes]);
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(this._vm);
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log('err', error);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetch({ commit }, { payload, loadMore }) {
    console.log('fetch loadMore', loadMore);
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllDocuments, payload),
      );
      const { nodes, pageInfo, totalCount } = response.data.allDocuments;
      commit('setList', { nodes, loadMore });
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(this._vm);
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log('err', error, payload);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchDocument({ commit }, payload) {
    // console.log('fetch loadMore', loadMore);
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.DocumentById, payload),
      );
      const { documentById } = response.data;
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(documentById);
      commit('setLoadings', 'value');
      return documentById;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log('err', error, payload);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async generateUrl({ commit }, payload) {
    console.log('generateUrl loadMore');
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.GenerateUrl, {
          input: payload,
        }),
      );
      const { generateDocumentUrl } = response.data;
      commit('setLoadings', { type: 'url', value: false });
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(this._vm);
      return generateDocumentUrl;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log('err', error, payload);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async deleteDoc({ commit }, payload) {
    console.log('getCount');
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.DeleteDocument, {
          input: {
            documentId: payload,
          },
        }),
      );
      const { ok, err, documentId } = response.data.deleteDocument;
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(ok, err, documentId);
      commit('setLoadings', { type: 'delete', value: false });
      return response.data.deleteDocument;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log(error);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async upload2({ commit }, payload) {
    const apiName = 'mainApi';
    const path = '/upload';
    const token = localStorage.getItem('token');
    const base64File = await toBase64(payload.body);
    const myInit = {
      body: base64File.split(',')[1],
      headers: {
        'x-document-name': payload.name,
        'x-document-type': payload.type,
        'x-document-directory': payload.directory,
        'x-library-id': payload.libraryId,
        Authorization: `Bearer ${token}`,
        // 'content-type': 'multipart/form-data; boundary=xxxxxxxxxxxx',
      },
    };

    try {
      const response = await API.post(apiName, path, myInit);
      const { uploadURL } = response;
      commit('setArtwork', uploadURL);
      return uploadURL;
    } catch (error) {
      console.log('%c base 64 file', 'color: red', error);
      this._vm.$message.error('Something went wrong');
      return { error: true, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'deleting', value: false });
    }
  },
  async upload({ commit }, payload) {
    // const apiName = 'mainApi';
    // const path = '/upload';
    const token = localStorage.getItem('token');
    const myInit = {
      // body: payload.body,
      headers: {
        'x-document-name': payload.name,
        'x-document-type': payload.type,
        'x-document-directory': payload.directory,
        // 'x-document-id': payload.id,
        'x-library-id': payload.libraryId,
        // 'content-type': 'multipart/form-data; boundary=xxxxxxxxxxxx',
        Authorization: `Bearer ${token}`,
      },
    };
    // formData.append("image", imagefile.files[0]);
    const url = `${awsconfig.aws_appsync_graphqlEndpoint}upload`;

    try {
      // const response = await API.post(apiName, path, myInit);
      // const base64File = await toBase64(payload.body);
      // console.log('%c base 64 file', 'color:lime', payload, base64File.split(',')[1]);
      const formData = new FormData();
      // formData.append('file', base64File.split(',')[1]);
      formData.append('file', payload.body);
      // formData.append('Content-Type', payload.type);
      console.log('>> formData >> ', formData.getAll);
      console.log('%c base 64 file', 'color:lime', payload, formData);
      const response = await axios.post(url, formData, {
        headers: {
          ...myInit.headers,
          'Content-Type': 'multipart/form-data',
        },
      });
      const { documentId } = response;
      return documentId;
    } catch (error) {
      console.log('%c base 64 file', 'color: red', error);
      this._vm.$message.error('Something went wrong');
      return { error: true, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'deleting', value: false });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
