/* eslint-disable object-shorthand */
/* eslint-disable object-shorthand */
<template>
  <div id="login">
    <div class="min-h-screen bg-uo-black flex">
      <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96" v-show="!showLoginMessage">
          <div>
            <svg class="h-16 w-auto text-white opacity-60" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <h2 class="mt-6 text-3xl font-extrabold text-white">
              Create new account
            </h2>
            <p class="mt-2 text-sm text-gray-400 max-w">
              Or
              <router-link
                :to="'/' + customer + '/login'"
                tag="a"
                class="font-medium text-indigo-400 hover:text-indigo-300">login to existing one</router-link>
            </p>
            <div class="message text-red-500 text-xs mt-1" v-if="errorMessage">
              {{ errorMessage }}
            </div>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <form @submit.prevent="register" class="space-y-6">
                <div>
                  <label for="email" class="block text-sm font-medium text-gray-300">
                    Email address
                  </label>
                  <div class="mt-1 relative">
                    <input
                      v-model="email"
                      id="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      :class="{'ring-2 ring-green-500': email && validation.isPassed('email')}">
                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" v-if="email && validation.hasError('email') && validation.isTouched('email')">
                        <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                      </div>
                  </div>
                  <div class="message text-red-500 text-xs mt-1">
                    {{ validation.firstError('email') }}
                    <i v-if="validation.isValidating('email')" class="fa fa-spinner fa-spin"></i>
                    <i v-if="email && validation.isPassed('email')" class="text-success fa fa-check-circle"></i>
                  </div>
                </div>
                <div class="space-y-1">
                  <label for="password"
                    class="block text-sm font-medium text-gray-300">
                    Password
                  </label>
                  <div class="mt-1 relative">
                    <input
                    v-model="password"
                    id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      v-if="password && validation.hasError('password') && validation.isTouched('password')">
                      <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  <div class="message text-red-500 text-xs mt-1" v-if="validation.isTouched('password')">
                    {{ validation.firstError('password') }}
                    <i v-if="validation.isValidating('password')" class="fa fa-spinner fa-spin"></i>
                    <i v-if="password && validation.isPassed('password')" class="text-success fa fa-check-circle"></i>
                  </div>
                </div>
                <div class="space-y-1">
                  <label for="password"
                    class="block text-sm font-medium text-gray-300">
                    Confirm Password
                  </label>
                  <div class="mt-1 relative">
                    <input
                    v-model="confirmPassword"
                    id="confirmPassword" name="confirmPassword" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      v-if="confirmPassword && validation.hasError('confirmPassword') && validation.isTouched('confirmPassword')">
                      <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  <div class="message text-red-500 text-xs mt-1" v-if="validation.isTouched('confirmPassword')">
                    {{ validation.firstError('confirmPassword') }}
                    <i v-if="validation.isValidating('confirmPassword')" class="fa fa-spinner fa-spin"></i>
                    <i v-if="confirmPassword && validation.isPassed('confirmPassword')" class="text-success fa fa-check-circle"></i>
                  </div>
                </div>
                <div>
                  <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="mx-auto w-full max-w-sm lg:w-96" v-show="showLoginMessage">
          <div>
            <img class="h-12 w-auto" src="../assets/logo.svg" alt="Workflow">
            <h2 class="mt-6 text-3xl font-extrabold text-white">
              Verify your email
            </h2>
            <p class="mt-2 text-sm text-white max-w">
              We have sent an email with a confirmation link to your email address. In
              order to complete the sign-up process, please click the confirmation link.
            </p>
            <router-link
              :to="'/' + customer + '/login'"
              tag="a"
              class="font-medium text-indigo-400 hover:text-indigo-300">|go to login page</router-link>
          </div>
        </div>
      </div>
      <div class="hidden lg:block relative w-0 flex-1">
        <img class="absolute inset-0 h-full w-full object-cover" src="../assets/bg.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  data() {
    return {
      user: null,
      email: null,
      password: null,
      confirmPassword: null,
      customer: null,
      confirmDirty: false,
      errorMessage: null,
      showLoginMessage: false,
      submitted: false,
    };
  },
  validators: {
    // eslint-disable-next-line
    email: (value) => Validator.value(value).required().email(),
    password: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    // eslint-disable-next-line
    'confirmPassword, password': function(confirmPassword, password) {
      console.log('sss');
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword).required().match(password);
      }
      return false;
    },
  },
  beforeCreate() {
  },
  mounted() {
    this.customer = this.$route.params.customer;
  },
  methods: {
    ...mapMutations({
      setSession: 'auth/setSession',
    }),
    async register() {
      this.submitted = true;
      const isValid = await this.$validate();
      console.log('login', isValid);
      if (!isValid) return;
      this.loading = true;
      try {
        this.isBtnLoading = true;
        const email = this.email.toLowerCase();
        const result = await this.$Amplify.Auth.signUp({
          email,
          username: email,
          password: this.password,
          attributes: {
            email,
            name: email,
            'custom:customerId': this.customer,
          },
        });
        const hasUser = !!result.user;

        if (!hasUser) {
          this.error = 'Something went wrong try again';
          this.isBtnLoading = false;
          return;
        }

        this.user = result.user;
        this.showLoginMessage = true;
      } catch (error) {
        const hasErrorCode = !!error.code;
        this.isBtnLoading = false;
        this.errorMessage = error.message;
        if (hasErrorCode) {
          this.error = error.message;
          return;
        }
        this.error = error;
      }
    },
  },
};
</script>

<style lang="scss">
#register {
  height: 100%;
  text-align: left;
  display: grid;
  align-content: center;
  .form-wrapper {
    width: 360px;
    margin: 0 auto;
    padding: 30px 30px 0px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 10px 13px -7px rgba(#000, 0.3), 5px 5px 15px 5px rgba(#000, 0);
    .hm-logo {
      max-width: 100px;
      display: block;
      margin: 0 auto;
      svg {
        max-width: 100px;
        max-height: 150px;
      }
    }
  }
  .register-form {
    max-width: 300px;
  }
  .register-form-forgot {
    float: right;
  }
  .register-form-button {
    width: 100%;
  }
}
</style>
