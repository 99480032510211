<template>
  <div id="app" class="bg-gray-100">
    <router-view/>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { Auth } from 'aws-amplify';

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userEmail: (state) => state.auth.userEmail,
      userId: (state) => state.auth.userId,
      customer: (state) => state.auth.customer,
      customers: (state) => state.customer.list,
    }),
  },
  created() {
    this.checkIfLogged();
  },
  mounted() {
    console.log('-> APP', this.$route.params.customer);
    if (this.$route.params.customer) {
      console.log('setCustomer');
      this.setCustomer(this.$route.params.customer);
      this.setCustomerId(this.$route.params.customer);
      this.fetchCustomer({ id: this.$route.params.customer });
    }
  },
  methods: {
    ...mapMutations({
      setSession: 'auth/setSession',
      setIsLoggedIn: 'auth/setIsLoggedIn',
      setCustomer: 'auth/setCustomer',
      setCustomerId: 'customer/setCustomerId',
    }),
    ...mapActions({
      fetch: 'customer/fetch',
      fetchCustomer: 'customer/fetchCustomer',
      fetchUser: 'auth/fetchUser',
    }),
    async checkIfLogged() {
      if (this.isLoggedIn) return;
      const result = await Auth.currentSession();
      console.log('USER SESSION', result);
      this.setSession(result);
      await this.fetchUser({ id: this.userId });
      await this.fetchCustomer({ id: this.$route.params.customer });
    },
  },
};
</script>
<style lang="scss">
@import url('./styles/styles.scss');
</style>
