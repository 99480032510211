<template>
  <div class="">
    <PageHeader>
      <nav class="sm:flex -mb-6" aria-label="Breadcrumb">
        <ol class="flex items-center space-x-4">
          <li>
            <div class="flex items-center">
              <router-link :to="{ path: '/' + customer + '/libraries' }"  class="text-sm font-medium text-gray-400 hover:text-gray-200">Libraries</router-link>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <!-- Heroicon name: solid/chevron-right -->
              <svg class="flex-shrink-0 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
              </svg>
              <router-link :to="{ path: '/' + customer + '/libraries/' + libraryId + '/documents' }"  class="ml-4 text-sm font-medium text-gray-400 hover:text-gray-200">{{library.displayName}}</router-link>
            </div>
          </li>
        </ol>
      </nav>
      <div class="relative inline-block text-left" v-click-outside="closeDropdown">
        <div @click="showDropdown = !showDropdown" class="cursor-pointer">
          Indices
          <span class="flex-shrink-0 h-5 w-5 text-gray-500 text-sm relative -top-1">
            <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="showDropdown" />
            <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="!showDropdown" />
          </span>
        </div>
        <div class="origin-top-center absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5" :class="{hidden: !showDropdown}">
          <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <router-link :to="{ path: '/' + customer + '/libraries/' + libraryId + '/documents' }"  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">Documents</router-link>
            <router-link :to="{ path: '/' + customer + '/libraries/' + libraryId + '/feeds' }"  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">Feeds</router-link>
            <router-link :to="{ path: '/' + customer + '/libraries/' + libraryId + '/indices' }"  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">Indices</router-link>
          </div>
        </div>
      </div>

      <div class="mt-4 flex md:mt-0 md:ml-4" slot="actions"
          v-click-outside="closeFilters">
        <button
          @click="showCreateNew = true"
          id="create-feed" type="button" class="mr-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">
          CREATE
          <i class="fas fa-plus ml-2 -mr-1 h-4 w-4"></i>
        </button>
      </div>
    </PageHeader>
    <div class="flex flex-col mt-5">
      <Indices :id="libraryId" ref="indices"></Indices>
    </div>
    <Modal :show.sync="showCreateNew">
      <span slot="header">Create Index</span>
      <Alert type="error" v-if="createError">Something went wrong. Please try again!</Alert>
        <div class="sm:pt-5">
          <div class="w-full">
            <!-- <FormControl v-model="newIndex.name" :error="validation.firstError('newIndex.name')" :inline="true" type="text" label="Name"></FormControl> -->
            <!-- newIndex.indexType{{newIndex.indexType}}
            <FormControl v-model="newIndex.indexType" :error="validation.firstError('newIndex.indexType')" :inline="true" label="Type"></FormControl> -->
            <FormControlCustom :error="validation.firstError('newIndex.indexType')" :inline="true" type="text" label="Type">
              <a-select
                style="width: 100%"
                placeholder="Please select"
                v-model="newIndex.indexType"
                @change="indexChanged"
                v-if="indexTypes"
              >
                <a-select-option v-for="(opt, index) in types" :key="opt" :value="index">
                  {{ indexTypes.names[index] }}
                </a-select-option>
              </a-select>
            </FormControlCustom>
            <FormControlTextarea v-model="newIndex.indexSettings" :error="validation.firstError('newIndex.indexSettings')" :inline="true" type="textarea" label="Settings"></FormControlTextarea>
            <!-- <FormControlCustom :error="validation.firstError('newIndex.indexSettings')" :inline="true" type="" label="Settings">
              <vue-json-editor v-model="newIndex.indexSettings" :expandedOnStart="true"></vue-json-editor>
            </FormControlCustom> -->
          </div>
      </div>
      <div slot="footer">
        <button class="btn-cancel" @click="showCreateNew = false">
          Cancel
        </button>
         <button type="submit" class="btn-action" @click="createIndex" :disabled="(!newIndex.indexType && newIndex.indexType !== 0) || loading">
          Create
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

const Jabber = require('jabber');

export default {
  data() {
    return {
      showCreateNew: false,
      showFilters: false,
      showDropdown: false,
      gen: new Jabber(),
      libs: [],
      libraryId: null,
      createError: false,
      loading: false,
      types: [0, 1],
      newIndex: {
        libraryId: null,
        indexType: null,
        indexSettings: `{
}`,
      },
    };
  },
  computed: {
    ...mapState({
      libraries: (state) => state.library.list,
      library: (state) => state.library.selected,
      docCount: (state) => state.library.count,
      customer: (state) => state.auth.customerId,
      customers: (state) => state.customer.list,
      indexTypes: (state) => state.indices.types,
    }),
  },
  validators: {
    // eslint-disable-next-line
    'newIndex.indexType': (value) => Validator.value(value).required(),
  },
  watch: {
    customers() {
      console.log('watch customers', this.customers);
    },
  },
  created() {
    this.libraryId = this.$route.params.libraryId;
  },
  mounted() {
    console.group('INDICES');
    console.log('-> INDICES');
    this.init();
  },
  beforeDestroy() {
    console.groupEnd('INDICES');
  },
  methods: {
    ...mapActions({
      fetchLibrary: 'library/fetchLibrary',
      create: 'indices/create',
      delete: 'indices/delete',
      getTypes: 'indices/getTypes',
    }),
    async init() {
      await this.fetchLibrary(this.libraryId);
      await this.getTypes();
      this.types = this.indexTypes.types;
      console.log('watch customers', this.libraryId);
    },
    async createIndex() {
      this.createError = false;
      this.loading = true;
      const isValid = await this.$validate();
      console.log('create', isValid);
      if (!isValid) return;
      const payload = {
        ...this.newIndex,
        libraryId: this.libraryId,
        indexType: this.indexTypes.types[this.newIndex.indexType],
        // customerId: this.customer,
      };
      const result = await this.create(payload);
      if (result.err) {
        this.createError = true;
        this.loading = false;
        return;
      }
      this.createError = false;
      this.loading = false;
      this.showCreateNew = false;
      this.$message.success('Index deleted');
      this.newIndex = {
        libraryId: this.libraryId,
        indexType: null,
        indexSettings: `{
        }`,
      };
      await this.$refs.indices.refresh();
      this.showCreateNew = false;
    },
    closeFilters() {
      this.showFilters = false;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    indexChanged(index) {
      this.newIndex.indexSettings = JSON.stringify(this.indexTypes.defaultSettings[index]);
      this.$forceUpdate();
    },
  },
};
</script>
