<template>
  <div class="">
    <PageHeader>
      Documents
      <div class="relative" slot="actions"
          v-click-outside="closeFilters">
      </div>
    </PageHeader>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="flex flex-col mt-5">
      <Documents></Documents>
    </div>
    <Modal :show.sync="showCreateNew">
      <span slot="header">Upload document</span>
        <div class="sm:pt-5">
          <div class="w-full">
            <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div class="space-y-1 text-center">
                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div class="flex text-sm text-gray-600">
                  <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                    <span>Upload a file</span>
                    <input id="file-upload" name="file-upload" type="file" class="sr-only">
                  </label>
                  <p class="pl-1">or drag and drop</p>
                </div>
                <p class="text-xs text-gray-500">
                  <!-- DOCX, PDF, HTML up to 10MB -->
                </p>
              </div>
            </div>
          </div>
      </div>
      <div slot="footer">
        <button class="btn-cancel" @click="showCreateNew = false">
          Cancel
        </button>
         <button type="submit" class="btn-action" @click="showCreateNew = false">
          Upload
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      showFilters: false,
      showCreateNew: false,
    };
  },
  computed: {
    ...mapState({
      documents: (state) => state.documents.list,
      docCount: (state) => state.library.count,
      customer: (state) => state.auth.customerId,
    }),
  },
  watch: {
  },
  mounted() {
    console.group('DOCUMENTS');
    console.log('-> DOCUMENTS');
  },
  beforeDestroy() {
    console.groupEnd('DOCUMENTS');
  },
  methods: {
    ...mapActions({
      fetch: 'documents/fetch',
      create: 'documents/create',
    }),
    closeFilters() {
      this.showFilters = false;
    },
  },
};
</script>
