import { Auth } from 'aws-amplify';

export default (to, from, next) => {
  console.log('guard', to, from, next);
  Auth.currentSession()
    .then((data) => {
      localStorage.setItem('token', data.accessToken.jwtToken);
      next();
    })
    .catch(() => {
      const { customer } = to.params;
      const path = `/${customer}/login`;
      const redirectRoute = {
        path,
        query: {
          redirect: to.fullPath,
        },
      };
      next(redirectRoute);
    });
};
