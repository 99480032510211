<template>
<div class="bg-red-700 min-h-screen ">
  <div class="max-w-full text-center mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between ">
    <div class="container max-w-6xl mx-auto">
      <div class="mt-10 w-full max-w-sm mx-auto">
        <div class="mt-1.5 relative text-center">
          <img src="../assets/logo.svg" class="w-full object-center">
        </div>
      </div>
      <div>
        <h2 class="text-4xl font-extrabold text-white sm:text-7xl sm:tracking-tight lg:text-9xl">404</h2>
        <p class="mt-5 text-xl text-white">
          Page does not exist
        </p>
      </div>
    </div>
  </div>
</div>

</template>
<script>
export default {
  data() {
    return {
    };
  },
  mounted() {
    console.log('-> 404');
  },
  methods: {
  },
};
</script>
