<template>
  <div class="">
    <PageHeader>
      <nav class="sm:flex -mb-6" aria-label="Breadcrumb">
        <ol class="flex items-center space-x-4">
          <li>
            <div class="flex items-center">
              <router-link :to="{ path: '/' + customer + '/libraries' }"  class="text-sm font-medium text-gray-400 hover:text-gray-200">Libraries</router-link>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <!-- Heroicon name: solid/chevron-right -->
              <svg class="flex-shrink-0 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
              </svg>
              <router-link :to="{ path: '/' + customer + '/libraries/' + libraryId + '/documents' }"  class="ml-4 text-sm font-medium text-gray-400 hover:text-gray-200">{{library.displayName}}</router-link>
            </div>
          </li>
        </ol>
      </nav>
      <div class="relative inline-block text-left" v-click-outside="closeDropdown">
        <div @click="showDropdown = !showDropdown" class="cursor-pointer">
          Feeds
          <span class="flex-shrink-0 h-5 w-5 text-gray-500 text-sm relative -top-1">
            <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="showDropdown" />
            <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="!showDropdown" />
          </span>
        </div>
        <div class="origin-top-center absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5" :class="{hidden: !showDropdown}">
          <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <router-link :to="{ path: '/' + customer + '/libraries/' + libraryId + '/documents' }"  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">Documents</router-link>
            <router-link :to="{ path: '/' + customer + '/libraries/' + libraryId + '/feeds' }"  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">Feeds</router-link>
            <router-link :to="{ path: '/' + customer + '/libraries/' + libraryId + '/indices' }"  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">Indices</router-link>
          </div>
        </div>
      </div>

      <div class="mt-4 flex md:mt-0 md:ml-4" slot="actions"
          v-click-outside="closeFilters">
        <button
          @click="showAttach = true"
          id="attach-feed" type="button" class="mr-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">
          Attach Feed
          <i class="fas fa-link ml-2 -mr-1 h-4 w-4"></i>
        </button>
        <button
          @click="showCreateNew = true"
          id="create-feed" type="button" class="mr-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">
          NEW
          <i class="fas fa-plus ml-2 -mr-1 h-4 w-4"></i>
        </button>
      </div>
    </PageHeader>
    <div class="flex flex-col mt-5">
      <Feeds :id="libraryId" ref="feeds"></Feeds>
    </div>
    <Modal :show.sync="showCreateNew">
      <span slot="header">Create Feed</span>
        <div class="sm:pt-5">
          <div class="w-full">
            <FormControl v-model="newFeed.displayName" :error="validation.firstError('newFeed.displayName')" :inline="true" label="Display Name"></FormControl>
            <FormControl v-model="newFeed.description" :error="validation.firstError('newFeed.description')" :inline="true" label="Type"></FormControl>
            <FormControlTextarea v-model="newFeed.indexSettings" :error="validation.firstError('newFeed.indexSettings')" :inline="true" type="textarea" label="Settings"></FormControlTextarea>
            <!-- <FormControlSelect v-model="newFeed.supportedExtensions" :error="validation.firstError('newFeed.indexSettings')" :inline="true" type="textarea" :options="extensions" label="Supported Extensions"></FormControlSelect> -->
            <!-- <FormControlSelect v-model="newFeed.supportedExtensions" :error="validation.firstError('newFeed.supportedExtensions')" :inline="true" type="textarea" :options="extensions" label="Supported Extensions"></FormControlSelect> -->
            <FormControlCustom :error="validation.firstError('newFeed.supportedExtensions')" :inline="true" type="text" label="Supported Extensions">
              <a-select
                mode="multiple"
                style="width: 100%"
                placeholder="Please select"
                v-model="newFeed.supportedExtensions"
              >
                <a-select-option v-for="opt in extensions" :key="opt" :value="opt">
                  {{ opt }}
                </a-select-option>
              </a-select>
            </FormControlCustom>
          </div>
      </div>
      <div slot="footer">
        <button class="btn-cancel" @click="showCreateNew = false">
          Cancel
        </button>
         <button type="submit" class="btn-action" @click="createFeed" :disabled="!newFeed.displayName">
          Create
        </button>
      </div>
    </Modal>
    <Modal :show.sync="showAttach">
      <span slot="header">Attach Feed</span>
        <div class="sm:pt-5">
          <div class="w-full">
            <div class="mt-7 mb-7" v-show="detachedFeeds.length === 0">
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                <svg
                  class="h-6 w-6 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  There is no feeds available
                </h3>
                <div class="mt-2">
                </div>
              </div>
            </div>
            <ul class="relative bg-white rounded-md -space-y-px feed-radio-list">
              <li v-for="item in detachedFeeds" :key="item.id">
                <!-- On: "bg-indigo-50 border-indigo-200 z-10", Off: "border-gray-200" -->
                <div class="relative border  p-4 flex flex-col md:pl-4 md:pr-6 md:grid md:grid-cols-3">
                  <label class="flex items-center text-sm cursor-pointer">
                    <input name="feed" v-model="selectedFeed" type="radio" :value="item.id" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 cursor-pointer border-gray-300" aria-describedby="plan-option-pricing-0 plan-option-limit-0">
                    <span class="ml-3 font-medium text-gray-900">{{item.displayName}}</span>
                  </label>
                  <p id="plan-option-pricing-0" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                  </p>
                  <!-- On: "text-indigo-700", Off: "text-gray-500" -->
                  <p id="plan-option-limit-0" class="ml-6 mb-0 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">
                    <span :class="item.type">
                      {{item.type}}
                    </span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
      </div>
      <div slot="footer">
        <button class="btn-cancel" @click="showAttach = false">
          Cancel
        </button>
         <button type="submit" class="btn-action" @click="attachFeed(selectedFeed)" :disabled="!selectedFeed || loading">
          Attach
          <span  v-if="loading"><i class="fas fa-circle-notch fa-spin  ml-2 -mr-1 h-4 w-4"></i></span>
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

const Jabber = require('jabber');

export default {
  data() {
    return {
      loading: false,
      showAttach: false,
      showCreateNew: false,
      showFilters: false,
      showDropdown: false,
      gen: new Jabber(),
      libs: [],
      libraryId: null,
      extensions: ['docx', 'pdf', 'txt', 'html'],
      selectedFeed: null,
      newFeed: {
        customerId: null,
        displayName: null,
        description: '',
        type: '',
        libraryId: null,
        createdBy: null,
        libraryDisplayName: null,
        libraryDescription: null,
        supportedExtensions: [],
      },
    };
  },
  computed: {
    ...mapState({
      libraries: (state) => state.library.list,
      library: (state) => state.library.selected,
      docCount: (state) => state.library.count,
      customer: (state) => state.auth.customerId,
      customers: (state) => state.customer.list,
      userEmail: (state) => state.auth.userEmail,
      list: (state) => state.feed.list,
    }),
    detachedFeeds() {
      return this.list.filter((el) => !el.attached);
    },
  },
  validators: {
    // eslint-disable-next-line
    'newFeed.displayName': (value) => Validator.value(value).required(),
    'newFeed.type': (value) => Validator.value(value).required(),
  },
  watch: {
    customers() {
      console.log('watch customers', this.customers);
    },
  },
  created() {
    this.libraryId = this.$route.params.libraryId;
  },
  mounted() {
    console.group('FEEDS');
    console.log('-> FEEDS');
    this.init();
  },
  beforeDestroy() {
    console.groupEnd('FEEDS');
  },
  methods: {
    ...mapActions({
      fetchLibrary: 'library/fetchLibrary',
      create: 'feed/create',
      delete: 'feed/delete',
      attach: 'feed/attach',
    }),
    async init() {
      await this.fetchLibrary(this.libraryId);
      console.log('watch customers', this.libraryId);
    },
    async createFeed() {
      const isValid = await this.$validate();
      console.log('create', isValid);
      if (!isValid) return;
      const payload = {
        ...this.newFeed,
        libraryId: this.libraryId,
        createdBy: this.userEmail,
        // customerId: this.customer,
      };
      const result = await this.create(payload);
      if (result.err) return;
      this.showCreateNew = false;
      this.newFeed = {
        libraryId: this.libraryId,
        indexType: null,
        createdBy: this.userEmail,
      };
      await this.$refs.feeds.refresh();
    },
    closeFilters() {
      this.showFilters = false;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    async attachFeed(item) {
      this.loading = true;
      console.log('attach', item);
      const input = {
        feedId: item,
        libraryId: this.libraryId,
      };

      const result = await this.attach(input);
      if (result.err) {
        this.$message.error('Something went wrong');
        this.loading = false;
        return;
      }
      this.$message.success('Feed attached');
      this.showAttach = false;
      this.loading = false;
      this.selectedFeed = null;
      await this.$refs.feeds.refresh();
    },
  },
};
</script>
