/* eslint-disable no-shadow */
import Vue from 'vue';

const state = {
  name: 'Search',
  loading: false,

  currentRoute: {
    name: '',
    routes: {
      path: '/dashboard',
      breadcrumbName: 'Home',
      children: [

      ],
    },
  },
};

const getters = {
  // eslint-disable-next-line
  uuidv4: () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  },
};

const mutations = {
  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },

  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },

  setRoutes: (state, payload) => {
    const matched = [
      ...payload.matched,
    ];
    const currentRoute = matched.pop();
    state.currentRoute.name = currentRoute.name;
    // Vue.set(state, '')
  },
  setRouteName: (state, payload) => {
    state.currentRoute.name = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
