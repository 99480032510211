/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../graphql';

const state = {
  name: 'Feeds',
  loading: false,
  list: [],
  detached: [],
  count: 0,
  hasNextPage: true,
  pageInfo: {},
  loadings: {
  },
  types: {},
};

const getters = {
};

const mutations = {
  setDetached: (state, payload) => {
    Vue.set(state, 'detached', payload);
  },
  setList: (state, payload) => {
    if (payload.loadMore) {
      Vue.set(state, 'list', [
        ...state.list,
        ...payload.nodes,
      ]);
    } else {
      Vue.set(state, 'list', payload.nodes);
    }
  },
  clearList: (state) => {
    Vue.set(state, 'list', []);
    Vue.set(state, 'hasNextPage', true);
  },
  setCount: (state, payload) => {
    Vue.set(state, 'count', payload);
  },
  setPageInfo: (state, payload) => {
    Vue.set(state, 'hasNextPage', payload.hasNextPage);
    Vue.set(state, 'pageInfo', payload);
  },

  setTypes: (state, payload) => {
    Vue.set(state, 'types', payload);
  },

  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },
};

const actions = {
  async fetch({ commit }, { payload, loadMore }) {
    console.log('fetch loadMore', payload);
    let libraryId = null;
    const input = JSON.parse(JSON.stringify({
      ...payload,
    }));
    if (payload.condition && payload.condition.libraryId) {
      libraryId = payload.condition.libraryId;
      delete input.condition.libraryId;
    }
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllFeeds, input),
      );
      const { nodes, pageInfo, totalCount } = response.data.allFeeds;
      if (libraryId) {
        const list = [];
        const detachList = [];
        nodes.forEach((feed) => {
          const libs = feed.libraryFeedsByFeedId.nodes.filter((el) => el.libraryId === libraryId);
          console.log('feed', libs);
          if (libs.length > 0) list.push({ ...feed, attached: true });
          else list.push({ ...feed, attached: false });
        });
        console.log('setList', list);
        console.log('setDetached', detachList);
        commit('setList', { nodes: list, loadMore });
        commit('setCount', list.length);
        commit('setDetached', detachList);
        commit('setPageInfo', pageInfo);
        // const doesExist = !!this._vm.$t('basic.delete1');
        console.log(this._vm);
        return nodes;
      }
      commit('setList', { nodes: nodes.map((el) => ({ ...el, attached: true })), loadMore });
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(this._vm);
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log('err', error, payload);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async deleteItem({ commit }, payload) {
    console.log('deleteItem');
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.DeleteFeed, {
          input: {
            feedId: payload,
          },
        }),
      );
      const { ok, err } = response.data.deleteFeed;
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(ok, err);
      commit('setLoadings', { type: 'delete', value: false });
      return response.data.deleteFeed;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log(error);
      return { ok: false, err: error.errors, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async detach({ commit }, payload) {
    console.log('detach');
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.DetachFeed, {
          input: payload,
        }),
      );
      const { ok, err } = response.data.detachFeed;
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(ok, err);
      commit('setLoadings', { type: 'detach', value: false });
      return ok;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log(error);
      return { err: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async attach({ commit }, payload) {
    console.log('deleteItem');
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AttachFeed, {
          input: payload,
        }),
      );
      const { ok, err } = response.data.attachFeed;
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(ok, err);
      commit('setLoadings', { type: 'delete', value: false });
      return ok;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log(error);
      return { err: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async create({ commit }, payload) {
    commit('setLoadings', { type: 'feed', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CreateFeed, {
          input: payload,
        }),
      );
      const { createFeed } = response.data;
      return createFeed;
    } catch (error) {
      return { ok: false, err: error.err, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'feed', value: false });
    }
  },
  async getTypes({ commit }) {
    console.log('getTypes');
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.IndexTypes),
      );
      const { totalCount } = response.data.indexTypes;
      commit('setTypes', totalCount);
      // const doesExist = !!this._vm.$t('basic.delete1');
      console.log(this._vm);
      return totalCount;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      console.log(error);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
