import Vue from 'vue';
import VueTailwind from 'vue-tailwind';
import ClickOutside from 'vue-click-outside';
import SimpleVueValidation from 'simple-vue-validator';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import VueTimeago from 'vue-timeago';
import { Popconfirm } from 'ant-design-vue';
import * as Ant from 'ant-design-vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import InfiniteLoading from 'vue-infinite-loading';
import Pagination from 'vue-pagination-2';
import Loading from 'vue-loading';
import vueJsonEditor from 'vue-json-editor';
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes';

import App from './App.vue';
import router from './router';
import store from './store';
import awsconfig from './aws-exports';
import './styles/tailwind.css';
import 'antd/dist/antd.css';
import 'ant-design-vue/lib/tag/style/css';
import 'ant-design-vue/lib/select/style/css';
import 'ant-design-vue/lib/input/style/css';
import 'ant-design-vue/lib/list/style/css';
import 'ant-design-vue/lib/upload/style/css';

import PageHeader from './components/PageHeader.vue';
import Modal from './components/Modal.vue';
import FormControl from './components/FormControl.vue';
import FormControlTextarea from './components/FormControlTextarea.vue';
import FormControlSelect from './components/FormControlSelect.vue';
import FormControlCustom from './components/FormControlCustom.vue';
import Documents from './components/Documents.vue';
import Feeds from './components/Feeds.vue';
import Libraries from './components/Libraries.vue';
import Indices from './components/Indices.vue';
import Alert from './components/Alert.vue';

// const components = {
//   //...
// };

Amplify.configure(awsconfig);

Vue.directive('ClickOutside', ClickOutside);
Vue.use(VueTailwind);
Vue.use(SimpleVueValidation);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(Popconfirm);
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
  locales: {
  },
});
Vue.use(InfiniteLoading, { /* options */ });

// Font awesome
library.add(fas);
dom.watch();

Vue.component(PageHeader.name, PageHeader);
Vue.component(Modal.name, Modal);
Vue.component(FormControl.name, FormControl);
Vue.component(FormControlTextarea.name, FormControlTextarea);
Vue.component(FormControlSelect.name, FormControlSelect);
Vue.component(FormControlCustom.name, FormControlCustom);
Vue.component(Documents.name, Documents);
Vue.component(Feeds.name, Feeds);
Vue.component(Libraries.name, Libraries);
Vue.component(Indices.name, Indices);
Vue.component(Alert.name, Alert);
Vue.component('vueJsonEditor', vueJsonEditor);

// ANT DESIGN
Vue.use(Ant.Tag);
Vue.component(Ant.Upload.name, Ant.Upload);
Vue.component(Ant.Upload.Dragger.name, Ant.Upload.Dragger);
Vue.component(Ant.Badge.name, Ant.Badge);
Vue.component(Ant.Tooltip.name, Ant.Tooltip);
Vue.component(Ant.Checkbox.name, Ant.Checkbox);
Vue.component(Ant.Button.name, Ant.Button);
Vue.component(Ant.Button.Group.name, Ant.Button.Group);
Vue.component(Ant.Input.name, Ant.Input);
Vue.component(Ant.Tag.name, Ant.Tag);
Vue.component(Ant.List.name, Ant.List);
Vue.component(Ant.List.Item.name, Ant.List.Item);
Vue.component(Ant.Tag.CheckableTag.name, Ant.Tag.CheckableTag);
Vue.component(Ant.Select.name, Ant.Select);
Vue.component(Ant.Select.Option.name, Ant.Select.Option);

Vue.component('pagination', Pagination);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(vueFilterPrettyBytes);

Vue.directive('loading', Loading);
Vue.prototype.$message = Ant.message;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
