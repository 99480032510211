<template>
  <div>
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="w-full divide-y divide-gray-200" :class="{loading: loading}">
            <thead class="bg-gray-50">
              <tr>
                <th @click="orderBy('TYPE')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Type
                  <span v-show="selectedOrder === 'TYPE'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th @click="orderBy('INDEX_STATE')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  State
                  <span v-show="selectedOrder === 'INDEX_STATE'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th @click="orderBy('USED_SPACE')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Used Space
                  <span v-show="selectedOrder === 'USED_SPACE'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th @click="orderBy('UPDATED_AT')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Updated
                  <span v-show="selectedOrder === 'UPDATED_AT'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th @click="orderBy('CREATED_AT')" scope="col" class="cursor-pointer px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Created
                  <span v-show="selectedOrder === 'CREATED_AT'">
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-show="!orderDir" />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-show="orderDir" />
                  </span>
                </th>
                <th scope="col" class="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="item in list" :key="item.id">
                <td class="px-3 py-4 whitespace-nowrap cursor-pointer"  @click="selected = item; showDetails = true">
                  <span :class="item.type">
                    {{item.type}}
                  </span>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm"
                  :class="item.state"  @click="selected = item; showDetails = true">
                  <span class="status h-4 w-4 rounded-full flex items-center justify-center" aria-hidden="true">
                    <span class="h-2 w-2 rounded-full"></span>
                  </span>
                  <span>{{item.state}}</span>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500"  @click="selected = item; showDetails = true">
                  <span v-if="item.usedSpace !== '0'">{{item.usedSpace | prettyBytes}}</span>
                  <span v-if="item.usedSpace === '0'">0</span>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <timeago :datetime="moment.utc(item.updatedAt)"></timeago>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <timeago :datetime="moment.utc(item.updatedAt)"></timeago>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <a-tooltip title="Delete index">
                    <button
                      @click="selected = item; showDelete = true;"
                      type="button" class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <svg class="h-5 w-5 text-red-400 inline-block cursor-pointer" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                    </button>
                  </a-tooltip>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-7 mb-7" v-show="list.length === 0">
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
          <svg
            class="h-6 w-6 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            There is no indices
          </h3>
          <div class="mt-2">
          </div>
        </div>
      </div>
      <!-- PAGINA -->
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <pagination v-model="page" :records="totalCount" @paginate="loadData"/>
      </div>
    </div>
    <!-- <infinite-loading spinner="waveDots" @infinite="loadMore"></infinite-loading> -->
    <Modal :show.sync="showDetails">
      <span slot="header">Index Details</span>
        <div class="sm:pt-5">
          <div class="w-full">
            <div class="border-t border-gray-200 px-4 py-5 sm:p-0 -mx-6">
              <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Type
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{selected.type}}
                  </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Used Space
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{selected.usedSpace}}
                  </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Settings
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <textarea v-model="selectedSettings" class="form-control" rows="5"></textarea>
                  </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Metadata
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <textarea v-model="selectedMetadata" class="form-control" rows="5"></textarea>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
      </div>
      <div slot="footer">
        <button class="btn-cancel" @click="showDetails = false">
          Close
        </button>
      </div>
    </Modal>
    <Modal :show.sync="showDelete">
      <span slot="header">Are you sure delete this index?</span>
        <div class="sm:pt-5">
          <div class="w-full">
            <FormControl v-model="deleteValue" label="Enter 'DELETE' and press delete button to remove index"></FormControl>
          </div>
      </div>
      <div slot="footer">
        <button class="btn-cancel" @click="showDelete = false">
          Cancel
        </button>
         <button type="submit" class="btn-action" :disabled="deleteValue.toLowerCase() !== 'delete'" @click="deleteItem(selected)">
          Delete
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'Indices',
  props: {
    id: String,
  },
  data() {
    return {
      moment,
      selectedOrder: 'TYPE',
      orderDir: false, // false = asc, true = desc
      pageCount: 0,
      pageSize: 25,
      page: 1,
      offset: 0,
      loading: true,
      showDetails: false,
      selected: {},
      selectedSettings: {},
      selectedMetadata: {},
      showDelete: false,
      deleteValue: '',
    };
  },
  computed: {
    ...mapState({
      list: (state) => state.indices.list,
      hasNextPage: (state) => state.indices.hasNextPage,
      totalCount: (state) => state.indices.count,
    }),
    orderDirString() {
      return this.orderDir ? 'DESC' : 'ASC';
    },
    orderByString() {
      return `${this.selectedOrder}_${this.orderDirString}`;
    },
    queryParams() {
      const input = {
        first: 25,
        orderBy: this.orderByString,
        offset: this.offset,
      };

      if (this.id) {
        input.condition = {
          libraryId: this.id,
        };
      }

      return input;
    },
  },
  watch: {
    selected(newValue) {
      this.selectedSettings = JSON.stringify(newValue.settings, undefined, 4);
      this.selectedMetadata = JSON.stringify(newValue.metadata, undefined, 4);
    },
  },
  mounted() {
    this.clearList();
    this.loadData(1);
  },
  methods: {
    ...mapMutations({
      clearList: 'indices/clearList',
    }),
    ...mapActions({
      fetch: 'indices/fetch',
      create: 'indices/create',
      deleteIndices: 'indices/deleteItem',
    }),
    async deleteItem(item) {
      const result = await this.deleteIndices(item.id);
      console.log('deleted', result);
      if (!result.ok || result.err) {
        this.$message.error('Something went wrong');
        return;
      }
      await this.refresh();
      this.$message.success('Index deleted');
      this.$emit('deleteLib', item);
      this.showDelete = false;
    },
    async orderBy(type) {
      this.loading = true;
      if (type === this.selectedOrder) {
        this.orderDir = !this.orderDir;
        this.selectedOrder = type;
        await this.loadData(this.page);
        this.loading = false;
        return;
      }
      this.orderDir = false;
      this.selectedOrder = type;
      await this.loadData(this.page);
      this.loading = false;
    },
    async refresh() {
      this.loadData(this.page);
    },
    async loadData(page) {
      this.loading = true;
      console.log('page', page);
      this.offset = page * this.pageSize - this.pageSize;
      await this.fetch({ payload: this.queryParams, loadMore: false });
      this.loading = false;
    },
  },
};
</script>
